import {createMuiTheme} from "@material-ui/core"

const customTheme = createMuiTheme({
    palette: {
        primary:{
            // light:'',
            main: '#0d0d77',
            // dark: '',
        },
    },
    typography:{
        h6:{
            fontWeight:'500'
        }
    }
});

export default customTheme