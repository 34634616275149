import React, { useEffect } from 'react'
// import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
// import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
// import { connect } from 'react-redux'
import useState from 'react-usestateref'
// import {fetchSpecificAmendment} from '../../Redux/APIs/FetchAmendmentList/FetchAmendmentListActions'
import { CircularProgress } from '@material-ui/core';
import parse from 'html-react-parser'
import FullScreenContent from '../../components/MainContent/FullScreenContent';

import { fetchSpecificSchedule } from '../../Redux/APIs/FetchDetails/FetchSchedules'
import KnowledgeGraphButton from '../../components/KnowledgeGraph/KnowledgeGraphButton'


const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        justifyContent:'center',
        alignSelf: 'center',
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 19,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 22,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
            fontSize: 23,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {
        width: '80%',
        margin: 'auto',
        fontSize: 17,
        paddingTop: 1,
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }
    },
    container: {
        align: 'center',

    },
    textContent: {
        marginTop: 15,

        [theme.breakpoints.up('xs')]: {
            fontSize: 14,
            // marginBottom:3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 15,
            // marginBottom:5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 17,
            // marginBottom:6,
        },
    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },

}));


function ScheduleInfo(props) {
    const classes = useStyles();

    const [title, setTitle, refTitle] = useState("");
    const [content, setContent, refContent] = useState("");
    const [spinner, setSpinner, refSpinner] = useState(true);
    const [present, setPresent, refPresent] = useState(true);
    const [data, SetData] = useState();


    // useEffect(()=>{
    //     props.setMenuItems([],4);
    // },[props])


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchSpecificSchedule(props.match.params.id)
            .then((res) => {
                // console.log('ScheduleInfo_res', res)
                SetData(res.data)
                if (res.data.title) {
                    setContent(res.data.content);
                    setTitle(res.data.title);
                } else {
                    setPresent(false);
                }
                setSpinner(false);
            })
            .catch((err) => {
                setSpinner(false);
                setPresent(false);
                console.log(err);
            });

    }, [])

    // console.log('ScheduleInfo_props', props)
    return (
        <>
            {
                refSpinner.current ?
                    <FullScreenContent>
                        <div className={classes.container}>
                            <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>
                        </div>
                    </FullScreenContent> :
                    refPresent.current === false ?
                        <FullScreenContent>
                            <div className={classes.container}>
                                <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3>
                            </div>
                        </FullScreenContent> :
                        <FullScreenContent>
                            <div className={classes.container}>
                                <h2 className={classes.heading}>{parse(refTitle.current)}
                                <KnowledgeGraphButton data={data} pathname={'laws/schedule'} previous={props.location.pathname} id={props.match.params.id}/>
                                </h2>
                                <hr className={classes.divider} />
                                <div className={classes.content}>

                                    {/* {parse(refContent.current)} */}

                                    {refContent.current.split('\n').map((item, index) => {
                                        return (<p className={classes.textContent} key={index}>
                                            {item}
                                        </p>)

                                    })}

                                </div>
                            </div>

                        </FullScreenContent>
            }

        </>

    )
}




export default ScheduleInfo
// const mapDispatchToProps = (dispatch) =>{
//     return {
//         setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
//     }
// }

// export default connect(
//     null,
//     mapDispatchToProps
//   )(IndividualAmendment)
