import React, { useEffect, useState } from 'react';
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { getLoginStatus, getUserEmail, getUsername } from '../../config/cookiesInfo';
import { Card } from 'react-bootstrap';
import { DeleteOutline } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: 30,
        fontWeight: 600,

        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
            fontSize: 22,
            marginBottom: 0,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            fontSize: 26,
        }
    },

    content: {
        width: '85%',
        margin: 'auto',
        paddingTop: 20,

        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 2,
        }

    },
    container: {
        align: 'center',

    },

    btnStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        fontWeight: 'initial',
        border: 'none',
        fontWeight: 'bold'

    },
    adjustWidth: {

        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '45%',
        }
    },
    labelStyle: {
        color: "gray",
        fontWeight: 500,

        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
            fontSize: 16,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            fontSize: 20,
        }
    },
    valueStyle: {
        display: 'flex',
        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
            fontSize: 16,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
            fontSize: 18,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 0,
            fontSize: 20,
        },

    }

}));
const UserProfile = () => {
    const history = useHistory()
    const classes = useStyles()

    useEffect(() => {
        if (!getLoginStatus()) {
            history.push("/login")
        }
    }, [])

    return (
        <FullScreenContent >
            <div style={{}} className={classes.container}>
                <div style={{ padding: '10px', }} className={classes.content}>
                    <Card>
                        <Card.Header><h2 className={classes.heading} style={{ paddingLeft: 0 }}>Profile</h2></Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <div style={{ marginTop: 0 }} >
                                    <label className={classes.labelStyle}>Name</label>
                                    <label className={classes.valueStyle} >{getUsername()}</label>
                                    <label className={classes.labelStyle} style={{paddingTop:20}}>Email</label>
                                    <label className={classes.valueStyle} >{getUserEmail()}</label>
                                    <div style={{marginTop:25,justifyContent:'center',alignItems:'center'}}>
                                        <label style={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'red', padding:5,borderRadius:5 ,color:'red',cursor:'pointer'}}
                                        onClick={()=>history.push("/delete-account")}>
                                        Delete Account
                                        </label>
                                    </div>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                   
                </div>
            </div>
        </FullScreenContent>
    )
}

export default UserProfile
