import { SET_MENU_ITEM } from "./SetCurrentMenuItemsTypes"

const initialState = {
    data : [],
    index : 0,
}


const setMenuItemReducer = (state = initialState, action) =>{
    switch(action.type){
        case SET_MENU_ITEM:
            return {
                ...state,
                data : action.value,
                index : action.index
            }
        default :
            return state;

    }
}

export default setMenuItemReducer