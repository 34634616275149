// import {FETCH_RESOURCE_SUCCESS,FETCH_RESOURCE_FAILURE} from "./ResourceMenuItemsTypes"

const initialState = {
    data : [
        {
            name : "Constituent Assembly Debates",
            nestedItems : [
                {
                    name : "Year 1946 (11)",
                    nestedItems : [],
                    pageLink : '/resource/constituentAssemblyDebates',
                    apiLink : '',
                    data:{
                        year :'1946'
                    }
                },
                {
                    name : "Year 1947 (42)",
                    nestedItems : [],
                    pageLink : '/resource/constituentAssemblyDebates',
                    apiLink : '',
                    data:{
                        year :'1947'
                    }
                },
                {
                    name : "Year 1948 (52)",
                    nestedItems : [],
                    pageLink : '/resource/constituentAssemblyDebates',
                    apiLink : '',
                    data:{
                        year :'1948'
                    }
                },
                {
                    name : "Year 1949 (154)",
                    nestedItems : [],
                    pageLink : '/resource/constituentAssemblyDebates',
                    apiLink : '',
                    data:{
                        year :'1949'
                    }
                },
                {
                    name : "Year 1950 (1)",
                    nestedItems : [],
                    pageLink : '/resource/constituentAssemblyDebates',
                    apiLink : '',
                    data:{
                        year :'1950'
                    }
                }
            ],
            pageLink : '',
            apiLink : '',
            data:{}
        },
        {
            name : "Supreme Court Judgements",
            nestedItems : [],
            pageLink : '/resource/supremeCourtJudgements',
            apiLink : '',
            data:{}
        },
    ]
}

const resourceMenuItemsReducer = (state = initialState, action) =>{
    return state;
}


export default resourceMenuItemsReducer


























// const resourceMenuItemsReducer = (state = initialState, action) =>{

//     switch(action.type){
//         case FETCH_RESOURCE_FAILURE:
//             return {
//                 data : [],
//                 error : action.error
//             }
//         case FETCH_RESOURCE_SUCCESS:
//             return {
//                 data : action.payload,
//                 error : ''
//             }
//         default :
//             return state;

//     }
// }


