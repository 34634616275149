export const FETCH_DEBATE_SUCCESS = 'fetchDebateSuccess';
export const FETCH_DEBATE_FAILURE = 'fetchDebateFailure';




export const SET_DEBATE_DATA = 'setDebateData';
export const SET_DEBATE_OFFSET = 'setDebateOffset';
export const SET_DEBATE_LOAD= 'setDebateLoad';

export const SET_DEBATE_LANGUAGE= 'setDebateLanguage';

export const SET_DEBATE_YEAR= 'setDebateYear';