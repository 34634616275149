import React, { useEffect, useState } from 'react';
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import { makeStyles } from '@material-ui/core/styles';

import { clearCookie, getLoginStatus } from '../../config/cookiesInfo';
import { useHistory } from 'react-router-dom';
import { ArrowBack, ArrowDropDown, ArrowDropDownCircle, ChevronLeft } from '@material-ui/icons';
import { Button, Dropdown } from 'react-bootstrap';
import firebase, { firebaseAnalytics } from '../../config/firebase-config'
import { deleteUserAccount, fetchAccountDeletionReason } from './deleteAccountActions';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';

const useStyles = makeStyles((theme) => ({
    heading: {
        paddingTop: 30,
        fontWeight: 600,

        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },

    content: {
        width: '85%',
        margin: 'auto',
        paddingTop: 20,

        [theme.breakpoints.up('xs')]: {
            paddingTop: 0,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 2,
        }

    },
    container: {
        align: 'center',
    },

    btnStyle: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 5,
        fontWeight: 'initial',
        border: 'none',
        fontWeight: 'bold'

    },
    adjustWidth: {

        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '45%',
        }
    },
    errorText: {
        color: 'red'
    }

}));
const DeleteAccount = () => {
    const history = useHistory()
    const classes = useStyles();
    const [formData, setFormData] = useState({ delete_reason: "", feedback: "" })
    const [selectedReason, selectReason] = useState({ delete_reason: "Select reason", id: null })
    const [errorData, setErrorData] = useState({});
    const [reasonList, setReasonList] = useState([])
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        if (!getLoginStatus()) {
            history.push("/login")
        } else {
            fetchAccountDeletionReason()
                .then((res) => {
                    if(res.data.result){
                        setReasonList(res.data.result)
                    }else{
                       logoutUser() 
                    }       
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [])

    const logoutUser = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                console.log('logout successful');
                clearCookie();
                history.push('/');

            })
            .catch((error) => {
                console.log('logout unsuccessful', error);
            })
    }
    const onCloseAccount = () => {
        if (formData.delete_reason == "") {
            setErrorData({ delete_reason: "Please select reason" })
        } else {
            setModalShow(true)
        }
    }

    const onDelete = () => {
        deleteTheUserAccount({ delete_reason_id: formData.delete_reason.id, feedback: formData.feedback })
    }

    const deleteTheUserAccount = (data) => {
        deleteUserAccount(data)
            .then((res) => {
                if (res.data.success !== true) {
                    alert(res.data.status);
                } else {
                    console.log(res.data.status);
                    alert(res.data.status);
                    logoutUser()
                    firebaseAnalytics.logEvent(`${res.data.data.user.email}_account_deleted`);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', borderColor: 'gray', cursor: 'pointer', borderStyle: 'solid', borderWidth: 1, padding: 10, borderRadius: 5, }} className={classes.adjustWidth}
            onClick={(e) => {
                e.preventDefault();
                onClick(e)
            }}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            >
                {children}
            </div>
            <ArrowDropDown />

        </div>

    ));
    return (
        <FullScreenContent>
            <div style={{}} className={classes.container}>
                <div style={{ padding: '10px', overflow: 'unset' }} className={classes.content}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h2 className={classes.heading} style={{ paddingLeft: 0 }}>Delete Account</h2>
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <ConfirmationModal show={modalShow} onHide={() => setModalShow(false)}
                            onDelete={onDelete}
                            title={"Are you sure to delete your account?"} />
                        <label style={{ fontSize: 15 }}>Reason for deleting your account?</label>
                        <Dropdown aria-expanded={false} class="btn btn-secondary dropdown-toggle">
                            <Dropdown.Toggle as={CustomToggle} className={classes.adjustWidth} style={{ overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: 'white', borderColor: 'gray', color: 'black', textAlign: 'left' }} variant="success" id="dropdown-basic">
                                {selectedReason.delete_reason}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {reasonList.map((item) => (
                                    <Dropdown.Item onClick={() => {
                                        setFormData({ ...formData, delete_reason: item })
                                        selectReason(item)
                                        setErrorData({})
                                    }} href="#/action-1">{item.delete_reason}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        {errorData.delete_reason && <p className={classes.errorText}>{errorData.delete_reason}</p>}
                        <div style={{ display: 'inline-grid', marginTop: '30px' }} className={classes.adjustWidth}>
                            <label>Would you like to share feedback with us?</label>
                            <textarea value={formData.feedback} onChange={(event) => setFormData({ ...formData, feedback: event.target.value })} style={{ marginLeft: 0, borderRadius: 5, height: 80 }} name="postContent" defaultValue="" />
                        </div>

                    </div>
                    <div style={{}} className={classes.adjustWidth}>
                        <span style={{ color: 'red' }}>Please note that all the data associated with the app will be deleted as per our
                            <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => history.push("/privacy-policy")}> privacy policy</span>.The deletion is permanent and the data cannot be recovered.</span>
                    </div>
                    <div style={{ display: 'flex', marginTop: '50px', borderWidth: 1 }} className={classes.adjustWidth}>
                        <Button variant="danger" onClick={onCloseAccount}>Delete</Button>
                        <Button variant="secondary" style={{ marginLeft: 50, }} onClick={() => history.push("/")}>Cancel</Button>
                    </div>
                </div>
            </div>
        </FullScreenContent>
    )
}

export default DeleteAccount