import React,{useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.jpg'
import {
    Divider,
    Drawer,
} from '@material-ui/core';

import { connect } from 'react-redux'
import LeftSidebarTree from './LeftSidebarTree';

import {IconButton,Hidden} from "@material-ui/core/";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from '@material-ui/icons/Menu';
import './LeftSidebar.css'

const useStyles = makeStyles((theme) => ({
    list: {
        // width: 270,
        [theme.breakpoints.up("xs")]: {
          width: 250,
        },
        [theme.breakpoints.up("sm")]: {
          width: 280,
        },
        [theme.breakpoints.up("md")]: {
          width: 270,
        },
        [theme.breakpoints.up("lg")]: {
          width: 280,
        },


      },
      fullList: {
        width: "auto"
      },
      displayProperty: {
        // display: "flex",
        // justifyContent: "center"
      },
      listItemText: {
        marginLeft: -15,
        fontSize: 17,
        fontWeight:600,

      },
      logo:{
        height: 100,
        width: 100,
        marginBottom:50,
        marginTop:50,
        borderRadius:5,
        
      },
      outerDiv:{
          display: "flex",
        justifyContent: "center"
      },
      listitems:{
          marginTop:8,
          color:'white',
        //   marginLeft:-30,
      },
      drawerBackground:{
        //   background : theme.palette.primary.main,
      },
      iconColor:{
          color:'white'
      },
      dividerColor:{
        background:'white',
        
      },

      // sidebarBtn: {
      //   position: "fixed",
      //   zIndex: 1,
      //   left: 5,
      //   top: "40%",
      //   margin: theme.spacing(1),
      //   color: "white",
      //   backgroundColor: theme.palette.primary.main,
      //   "&:hover": {
      //     backgroundColor: theme.palette.primary.main,
      //   },
      //   [theme.breakpoints.up("xs")]: {
      //     padding:0
      //   },
      //   [theme.breakpoints.up("sm")]: {
      //     padding: 2
      //   }
      // },




      sidebarMenuBtn: {
        position: "fixed",
        zIndex: theme.zIndex.drawer + 1,
        margin: theme.spacing(1),
        color:'white', //theme.palette.primary.main
        background:'none',
        "&:hover": {
          color: '#e5e5e5',
        },
      },

      menuIconStyle: {
          fontSize: 27,
      },







      // iconStyle: {
      //   fontSize: 40,
      // },
      outlineStyle:{
        outline:'none !important'
      },
      permanent_sidebar_style:{
        marginTop:160,
      }

}));




const LeftSideBar=React.forwardRef((props,ref)=>{
    const classes = useStyles();
    const [state, setState] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" 
        && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState(open);
    };

    return (
      <>
      <Hidden mdUp implementation="css">
      {    true? //mobile menu
            (<div>
              {/* <Button onClick={toggleDrawer(true)}>Leftside bar</Button> */}


              <IconButton
              classes={{
                root:classes.outlineStyle
              }}
              onClick={toggleDrawer(true)} aria-label="delete" 
              className={`${classes.sidebarMenuBtn} positioning`}
              // style={{
              //   zIndex:1000,
              //   // position:relatve
              // }}
              >
                  <MenuIcon className={classes.menuIconStyle} />
                </IconButton>






              {/* <IconButton
              classes={{
                root:classes.outlineStyle
              }}
              onClick={toggleDrawer(true)} aria-label="delete" className={classes.sidebarBtn}>
                  <ChevronRightIcon className={classes.iconStyle} />
                </IconButton> */}




                <Drawer anchor={"left"} 
                    open ={state} 
                    // variant="permanent"
                    onClose={toggleDrawer(false)} 
                    classes={{paper:classes.drawerBackground}}>
                        <div className={classes.outerDiv} >
                            <img alt='not able to display' src={logo} className={classes.logo} />
                        </div>
                        <Divider className={classes.dividerColor}/>
                        <div
                            className={`${classes.list} ${classes.displayProperty}`}
                            role="presentation"
                            // onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}>

                              {/* <DummyComponent/> */}
                            <LeftSidebarTree/>
                        </div>
                </Drawer>
        </div>):
        <></>}
    </Hidden>




    <Hidden smDown implementation="css">
      {    true?
            (
            <div>
              
                <Drawer anchor={"left"} 
                    variant="permanent"
                    classes={{paper:classes.drawerBackground}}
                    >
                        <div className={`${classes.outerDiv} ${classes.permanent_sidebar_style}`}>
                             {/* <img alt='not able to display' src={logo} className={classes.logo} />  */}
                        </div> 
                        <Divider className={classes.dividerColor}/>
                        <div
                            className={`${classes.list} ${classes.displayProperty}`}
                            role="presentation"
                            // onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}

                            style={{
                              paddingLeft:10,
                              paddingBottom:30,
                            }}
                            >

                            <LeftSidebarTree/>
                        </div>
                </Drawer>
        </div>):
        <></>}
    </Hidden>



    </>
    )
});



// export default LeftSideBar

const mapStateToProps = (state) =>{
  return {
    displayLeftSidebar : state.displayLeftSidebar.displayLeftSidebar,
  }
}

export default connect(
  mapStateToProps,
  null
)(LeftSideBar)

