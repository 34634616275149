export const FETCH_JUDGEMENT_SUCCESS = 'fetchJudgementSuccess';
export const FETCH_JUDGEMENT_FAILURE = 'fetchJudgementFailure';


export const SET_JUDGEMENT_DATA = 'setJudgementData';
export const SET_JUDGEMENT_OFFSET = 'setJudgementOffset';
export const SET_JUDGEMENT_LOAD= 'setJudgementLoad';

export const SET_JUDGEMENT_LANGUAGE= 'setJudgementLanguage';

export const SET_JUDGEMENT_YEAR= 'setJudgementYear';


export const SET_JUDGEMENT_FROM= 'setJudgementFrom';
export const SET_JUDGEMENT_TO= 'setJudgementTo';