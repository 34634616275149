import {FETCH_CONSTITUTION_SUCCESS,FETCH_CONSTITUTION_FAILURE} from "./ConstitutionMenuItemsTypes"
import store from '../Store/store'

import {HOST} from '../../config/config'

import axios from 'axios'
export const fetchConstitutionSuccess = (data) => {
    return {
        type : FETCH_CONSTITUTION_SUCCESS,
        payload : data
    }
}
export const fetchConstitutionFailure = (error) => {
    return {
        type : FETCH_CONSTITUTION_FAILURE,
        payload : error
    }
}

export const fetchConstitutionData = () => {
    return   async (dispatch)=>{
        try{
            let language_type = store.getState().toggleLanguage.currentLanguageType;
            // console.log(store.getState().toggleLanguage.currentLanguageType);
            const data = await axios.get(`${HOST}parts/?language_type=${language_type}`);
            // console.log(data.data.results.length);
    
            const parts = data.data;
    
            let arr = [], i=0;
    
            for(i=0;i<parts.length;i++){
                let part = parts[i];
                if(part.chapter===-1)
                {
                    arr.push({
                        name : part.title,
                        // pageLink : '/constitution/parts/chapters/articles',
                        pageLink : `/constitution/parts/chapters/articles/${part.part_id}/${0}`,
                        nestedItems : [],
                        data :{

                            chapterId : 0,
                            partId : part.part_id,
                            part : part.title,
                            chapter : null

                        }
                    });
    
                } else {
                    const info = await axios.get(`${HOST}chapters/?language_type=${language_type}&part_id=${part.part_id}`);
    
                    let chapters = info.data;
                    chapters.sort((a,b)=>{
                        return a.id - b.id;
                    })
                    // console.log(part.title);
    
                    let chapterarr = chapters.map((chapter)=>{
                        // console.log(chapter);
                        return {
                            name : chapter.title,
                            nestedItems :[],
                            // pageLink : '/constitution/parts/chapters/articles',
                            pageLink : `/constitution/parts/chapters/articles/${part.part_id}/${chapter.chapter_id}`,
                            data :{
                                chapterId : chapter.chapter_id,
                                partId : part.part_id,
                                part : part.title,
                                chapter : chapter.title
                            }
                        }
    
                    });
    
                    arr.push({
                        name : part.title,
                        id : part.part_id,
                        pageLink : '/constitution/parts/chapters/articles',
                        nestedItems : chapterarr,
                        data :{}
                    });
                }
            }
    
            let result = [
                {
                    name : "Preamble",
                    nestedItems : [],
                    pageLink : '/constitution/preamble',
                    apiLink : '',
                    data:{}
                },
                {
                    name : "Parts",
                    nestedItems : arr,
                    pageLink : '',
                    apiLink : '',
                    data:{}
                },
                {
                    name : "Amendments",
                    nestedItems : [],
                    pageLink : '/constitution/amendments',
                    apiLink : '',
                    data:{}
                },
                {
                    name : "Schedules",
                    nestedItems : [],
                    pageLink : '/constitution/schedules',
                    apiLink : '',
                    data:{}
                },
            ];

            if(language_type===2){
                result[0].name = "प्रस्तावना";
                result[1].name = "भाग";
                result[2].name = "संशोधन";
                result[3].name = "अनुसूची";
            }

            dispatch(fetchConstitutionSuccess(result));
            // console.log(arr);
            // return arr;
    
        }
        catch(e){
            console.log(e);
            dispatch(fetchConstitutionFailure(e));

        }
    
    }
}