import { HOST } from '../../config/config'
import axios from 'axios'
import { getToken } from '../../config/cookiesInfo'


export const AdminDashboardData = (filter) => {
    console.log('send_filter==>AdminDashboardData', filter)
    return new Promise((resolve, reject) => {
        axios.get(`${HOST}listknowledgegraph/?${filter.filter_type}&object_type=${filter.object_type}&limit=20&offset=${filter.offset_type}`, {
            headers: {
                "Authorization": `Token ${getToken()}`
            },
        })
            .then((res) => {
                resolve(res);
                // console.log('AdminDashboardData_res',res.data)
            }).catch((err) => {
                reject(err)
            });
    })
}


export const postKnowledgeGraphReviewversion = (props_data) => {
    // console.log('data-send==>', props_data);
    return new Promise((resolve, reject) => {
        axios(`${HOST}reviewversion/`, {
            method: 'post',
            headers: {
                "Authorization": `Token ${getToken()}`
            },
            data: props_data
        })
            .then((res) => {
                resolve(res);
                // console.log('postKnowledgeGraphReviewversion_res',res)
            }).catch((err) => {
                reject(err)
            });
    })
};


export const patchKnowledgeGraphReviewversion = (props_data) => {
    // console.log('data-send==>', props_data);
    return new Promise((resolve, reject) => {
        axios(`${HOST}reviewversion/`, {
            method: 'patch',
            headers: {
                "Authorization": `Token ${getToken()}`
            },
            data: props_data
        })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
    })
};