import React from 'react'
// import LeftSidebar from '../../components/SidebarSection/LeftSidebar'
import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { connect } from 'react-redux'
import Login from '../../components/Login/Login'

import {getLoginStatus} from '../../config/cookiesInfo'

import { firebaseAnalytics } from '../../config/firebase-config'
import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions';
import { useHistory } from 'react-router-dom'

function ResourcePage(props) {

    const history = useHistory();

    React.useEffect(()=>{
        // console.log('sasa');
        if(getLoginStatus()){
            props.setMenuItems([],2);
            firebaseAnalytics.logEvent(`ResourcePage_visited`);
        } else {
            props.displayLeftSidebar(false);
            props.setMenuItems([],4);
        }
        
    },[props])

    const moveTo = ()=>{
        history.push('/resource/constituentAssemblyDebates')
    }


    return (
        <>

        {
            //Cookies.get('logInStatus')
            getLoginStatus()?
                moveTo()
            :   <Login pathToGo = {'/resource/constituentAssemblyDebates'}/>
        }

{/* <div style={{display:'flex'}}>
                    <h1 style={{margin:'auto',marginTop:'30%'}}>ResourcePage</h1>
                </div> */}
        
        </>


    )
}



// export default ResourcePage

const mapDispatchToProps = (dispatch) =>{
    return {
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
        displayLeftSidebar : (val) => dispatch(displayLeftSidebar(val)),
    }
}

export default connect(
    null,
    mapDispatchToProps
  )(ResourcePage)




