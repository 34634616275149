import React from 'react'
// import MainContentSection from '../MainContent/MainContentSection'
import {TextField, Box, Button, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useFormik} from 'formik'
// import axios from 'axios'
import { connect } from 'react-redux'

import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'

import {setToken,setLoginStatus, getLoginStatus,setUserEmail,setUsername} from '../../config/cookiesInfo'

import {signUpUser} from '../../Redux/APIs/SignUp/SignUpActions'
import { Redirect,useHistory } from 'react-router-dom';
import FullScreenContent from '../MainContent/FullScreenContent';


import { firebaseAnalytics } from '../../config/firebase-config'



const useStyles = makeStyles((theme) => ({
    root: {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,//color: 'red',
        
      },
      '& .MuiOutlinedInput-root': {
        maxHeight: 45,
        [theme.breakpoints.down('xs')]:{
            maxHeight: 45,
            
        },
        
            '& fieldset': {
                border:'3px solid red',
                borderColor: theme.palette.primary.main,

                
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                //borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
                border:'3px solid red',
                borderColor: theme.palette.primary.main,
            },
      },
    },

    boxContainer:{
        borderRadius:7,
        backgroundColor:'white',
        // border:'3px solid black',
        margin:'auto',
        maxWidth:380,
        // minHeight:410,
       
        [theme.breakpoints.up('xs')]:{
            width:'85%',
            paddingBottom:40,
        },
        [theme.breakpoints.down('sm')]:{
            // minHeight:390,
            paddingBottom:30,
        },
        

    },
    content:{ 
        width:'100%',
        paddingTop:50,
        [theme.breakpoints.down('xs')]:{
            paddingTop:25,
        }
    },
    container:{
        align:'center',
    },
    formStyle:{
        display:'grid',
        margin:'auto',
        maxWidth:280,
        [theme.breakpoints.up('xs')]:{
            width : '85%',
        }
    },
    txtemail:{
        marginTop:15,
    },
    txtPassword:{
        marginTop:15,
    },
    loginButton:{
        marginTop:20,
    },
    forgotPassword:{
        fontSize:13,
        marginTop:9,
        marginBottom:0,
        cursor:'pointer'
    },
    signUp:{
        fontSize:13,
        marginTop:5,
        cursor:'pointer'
    },
    googleButton:{
        color:'red',
        backgroundColor:'black',
        marginTop:15,
    },
    loginText:{
        fontSize:25,
        margin:'auto',
        fontWeight:600,
        marginBottom:20,
        [theme.breakpoints.down('xs')]:{
            marginTop:-10,
        }
        
    },
    txtConfirmPassword:{
        marginTop:15,
    },
    txtname:{
        // marginBottom:15,
    },
    dividerStyle:{
        height:2,
        marginTop:25,
        marginBottom:5,
    },
    errorMessage:{
        color:'red',
        marginBottom:0,
    }
  }));




const initialValues = {
    name:'',
    email:'',
    password:'',
    confirmPassword:''
};



const validate = values => {
    let errors = {};

        if(!values.name){
            errors.name = 'Required'
        }

        if(!values.email){
            errors.email = 'Required'
        } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
            errors.email = 'Invalid email format'
        }


        if(!values.password){
            errors.password = 'Required'
        }

        if(!values.confirmPassword){
            errors.confirmPassword = 'Required'
        }

        if(values.confirmPassword && values.confirmPassword!==values.password){
            errors.confirmPassword = 'Password and confirm password should be same'
        }
        
    return errors;
}



function SignUp(props) {
    // console.log('SignUp_props',props)
    const classes = useStyles();
    const history = useHistory();




    const onSubmit = values => {

        const data={
            email : values.email,
            password : values.password,
            name : values.name,
        }
        
        console.log(data);
        signUpUser(data)
        .then((res)=>{
    
            if(res.data.success!==true){
                alert('Sign UP unsuccessfull '+ res.data.status);
                // console.log(res);
    
            } else {
                console.log('Successfully Signed Up/');
                // console.log(res);
                // console.log('Access Token : ',res.data.data.access_token);
                // alert('Sign UP Successful '+ res.data.status);
    
                setToken(res.data.data.access_token);
                setLoginStatus(true);
                setUsername(res.data.data.user.name);
                setUserEmail(res.data.data.user.email);
                // setUserPicture(res.data.data.user.picture);
                // history.push('/');

                firebaseAnalytics.logEvent(`${res.data.data.user.email}_signedUp`);
                
                // history.push(props.location.state?props.location.state.pathToGo:'/');
                if (props.location && props.location.state){
                    // console.log('if_props==>',props)
                    history.push(props.location.state.pathToGo);
                }
                else{
                    // console.log('else_props==>',props)
                    if (props.pathToGo) {
                        // console.log('pathToGo_if')
                        history.push(props.pathToGo)    
                    }
                    else{
                        // console.log('pathToGo_else')
                        history.push(props.location.location.pathname,props.location.location.state);
                    }
                }
                
                // Cookies.set('token',res.data.data.access_token);
                // Cookies.set('logInStatus',true);
                //redirecting to specific page
            }
                
            
        })
        .catch((error)=>{
            console.log(error);
        }) 
    }
    
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });

    React.useEffect(()=>{
            props.setMenuItems([],4);
    },[props])

    return (
        <>
            {
                getLoginStatus()?
                <Redirect to={{
                    pathname:'/',
                }} />:

                <FullScreenContent>
                    <div className={classes.container}>
                        <div className={classes.content}>

                            <Box style={{paddingTop:20}} className={classes.boxContainer}>
                                <form className={classes.formStyle}
                                noValidate 
                                onSubmit={formik.handleSubmit}
                                // onSubmit={onFormSubmit}
                                autoComplete="off">

                                    <h5 className={classes.loginText}>SignUp</h5>

                                    <TextField 
                                        className={classes.txtname}
                                        classes={{
                                            root : classes.root
                                            }}
                    
                                        // value={email} 
                                        // onChange={(e)=>setEmail(e.target.value)} 
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        name='name'
                                        id="name" label="name" type="text" variant="outlined" />
                                        {formik.touched.name && formik.errors.name?<p className={classes.errorMessage}>{formik.errors.name}</p>:null}

                                    <TextField 
                                        className={classes.txtemail}
                                        classes={{
                                            root : classes.root
                                            }}
                    
                                        // value={email} 
                                        // onChange={(e)=>setEmail(e.target.value)} 

                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        name='email'
                                        id="email" label="Email" type="email" variant="outlined" />
                                        {formik.touched.email && formik.errors.email?<p className={classes.errorMessage}>{formik.errors.email}</p>:null}

                                    <TextField
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                        name='password'

                                        // value={password}
                                        // onChange={(e)=>setPassword(e.target.value)}
                                        id="password"
                                        label="Password"
                                        type="password"
                                        variant="outlined"
                                        classes={
                                            {
                                            root : classes.root
                                            }
                                        }
                                        className={classes.txtPassword}
                                    />
                                    {formik.touched.password && formik.errors.password?<p className={classes.errorMessage}>{formik.errors.password}</p>:null}

                                    <TextField

                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.confirmPassword}
                                        name='confirmPassword'
                                        // value={confirmPassword}
                                        // onChange={(e)=>setConfirmPassword(e.target.value)}
                                        id="confirmpassword"
                                        label="Confirm Password"
                                        type="password"
                                        variant="outlined"
                                        classes={
                                            {
                                            root : classes.root
                                            }
                                        }
                                        className={classes.txtConfirmPassword}
                                    />
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword?<p className={classes.errorMessage}>{formik.errors.confirmPassword}</p>:null}


                                    <Button 
                                    className={classes.loginButton}
                                    type='submit'
                                    // onClick={()=>alert(""+email+"-"+password)}
                                    variant="contained" 
                                    color="primary" 
                                    disableElevation>
                                        Sign Up
                                    </Button>


                                    {/* <p className={classes.forgotPassword}>Forgot Password?</p>
                                    <p className={classes.signUp}>Don't have an account? Sign Up</p> */}
{/* 
                                    <Divider
                                    className={classes.dividerStyle}
                                    
                                    /> */}

                                    {/* <Button 
                                    className={classes.googleButton}
                                    variant="contained" 
                                    disableElevation>
                                        Sign In with Google
                                    </Button> */}
                                </form>

                                
                            </Box>
                        </div>
                    </div>


                </FullScreenContent>

            }
        
        </>
        
        
    )
}

// export default SignUp

const mapDispatchToProps = (dispatch) =>{
    return {
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
    }
}

export default connect(
    null,
    mapDispatchToProps
  )(SignUp)