import { FETCH_DEBATE_FAILURE, FETCH_DEBATE_SUCCESS} from "./FetchDebateListTypes"

import {SET_DEBATE_DATA, SET_DEBATE_LOAD, SET_DEBATE_OFFSET, SET_DEBATE_LANGUAGE, SET_DEBATE_YEAR} from "./FetchDebateListTypes"




const initialState = {
    data : [],
    error : '',
    offset : 0,
    loadMore : true,
    language : 1,
    year : 1946,
}

const fetchDebateListReducer = (state = initialState, action) =>{

    switch(action.type){
        case FETCH_DEBATE_FAILURE:
            return {
                ...state,
                data : [],
                error : action.error
            }
        case FETCH_DEBATE_SUCCESS:
            return {
                ...state,
                data : action.payload,
                error : '',
                offset : action.offset,
                loadMore : action.loadMore,

            }
        case SET_DEBATE_DATA:
            return {
                ...state,
                data : action.payload,
                // error : action.error
            }
        case SET_DEBATE_LOAD:
            return {
                ...state,
                loadMore : action.payload,
                // error : action.error
            }
        case SET_DEBATE_OFFSET:
            return {
                ...state,
                offset : action.payload,
                // error : action.error
            }
        case SET_DEBATE_LANGUAGE:
            return {
                ...state,
                language : action.payload,
                // error : action.error
            }
        case SET_DEBATE_YEAR:
            return {
                ...state,
                year : action.payload,
                // error : action.error
            }

        default :
            return state;

    }
}

export default fetchDebateListReducer