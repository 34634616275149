import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'



export const searchList = (text,catagory) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}search/?text=${text}&category=${catagory}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })      
        .then((res)=>{
            // console.log('Search with auth',res)
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });
    })
}


export const searchListWithoutAuthentication = (text,catagory) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}open_search/?text=${text}&category=${catagory}`)       
        .then((res)=>{
            // console.log('Search without auth',res)
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });
    })
}