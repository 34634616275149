import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchPreambles = (id) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}preamble/?law=${id}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}




export const fetchSpecificPreamble = (id) => {

    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}preamble/${id}/`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
    
}
