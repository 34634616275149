import React,{useEffect} from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { connect } from 'react-redux'
import useState from 'react-usestateref'
import {fetchSpecificJudgement} from '../../Redux/APIs/FetchJudgementList/FetchJudgementListActions'
import {CircularProgress} from '@material-ui/core';
import parse from 'html-react-parser'

const useStyles = makeStyles((theme) => ({
    heading:{
        textAlign:'center',
        paddingTop:30,
        fontWeight:600,
        paddingLeft:20,
        paddingRight:20,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]:{
            paddingTop:15,
            fontSize:19,
            marginBottom:-7,
        },
        [theme.breakpoints.up('sm')]:{
            paddingTop:25,
            fontSize:22,
        },
        [theme.breakpoints.up('md')]:{
            paddingTop:31,
            fontSize:23,
        },
        [theme.breakpoints.up('lg')]:{
            paddingTop:35,
            fontSize:26,
        }
    },
    divider:{
        width:'90%',
        align:'center',
        backgroundColor:'black'
    },
    content:{ 
        width:'80%',
        margin:'auto',
        // fontSize:17,
        paddingTop:1,
        [theme.breakpoints.up('xs')]:{
            marginTop:0,
            fontSize:15,
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:0,
            fontSize:16,
        },
        [theme.breakpoints.up('md')]:{
            marginTop:10,
            fontSize:17,
        }
    },
    container:{
        align:'center',
        
    },
    textContent:{
        marginTop:30,
    },
    spinner:{
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color:'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer:{
        display:"flex"
    },
   
}));


function IndividualAmendment(props) {
    const classes = useStyles();

    const [title,setTitle,refTitle] = useState("");
    const [content,setContent,refContent] = useState("");
    const [spinner,setSpinner,refSpinner] = useState(true);
    const [present,setPresent,refPresent] = useState(true);
    const [judges_name,setJudges_name,refJudges_name] = useState([]);


    useEffect(()=>{
        window.scrollTo(0,0);
        props.setMenuItems([],2);
    },[props])


    useEffect(()=>{
        fetchSpecificJudgement(props.match.params.id)
            .then((res)=>{
                if(res.data.title){
                    setContent(res.data.content);
                    setTitle(res.data.title);
                    setJudges_name(res.data.judges_name);
                } else {
                    setPresent(false);
                }
                setSpinner(false);
            })
            .catch((err)=>{
                setSpinner(false);
                setPresent(false);
                console.log(err);
            });

    },[])


    return (
        <>
        {
            refSpinner.current?
                <MainContentSection>
                    <div className={classes.container}>
                    <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner}/></div>
                    </div>
                </MainContentSection>:
                refPresent.current===false?
                    <MainContentSection>
                        <div className={classes.container}>
                            <h3 className={classes.heading} style={{paddingTop:50}}>No data found</h3>
                        </div>
                    </MainContentSection>:
                    <MainContentSection>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>{refTitle.current}</h2>
                            <hr className={classes.divider}/>
                            <div className={classes.content}>

                            <h6><b>Judges Name :</b></h6>
                            <ul>
                            {
                                refJudges_name.current.map((name,index)=><li key={index}>{name}</li>)
                            }
                            </ul>

                            {parse(refContent.current)}
                            {/* {refContent.current.split('\n').map((item,index)=>{
                                return (<p className={classes.textContent} key={index}>
                                    {item}
                                </p>)

                            })} */}
                            
                            </div>
                        </div>
                        
                    </MainContentSection>
        }
       
        </>
       
    )
}

const mapDispatchToProps = (dispatch) =>{
    return {
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
    }
}

export default connect(
    null,
    mapDispatchToProps
  )(IndividualAmendment)
