import {FETCH_AMENDMENT_SUCCESS,FETCH_AMENDMENT_FAILURE} from "./FetchAmendmentListTypes"


import {SET_AMENDMENT_DATA,SET_AMENDMENT_LOAD,SET_AMENDMENT_OFFSET,SET_AMENDMENT_LANGUAGE} from "./FetchAmendmentListTypes"


// import store from '../../Store/store'
import {HOST} from '../../../config/config'
import axios from 'axios'


export const setAmendmentData = (data) => {
    return {
        type : SET_AMENDMENT_DATA,
        payload : data,
    }
}
export const setAmendmentOffset = (offset) => {
    return {
        type : SET_AMENDMENT_OFFSET,
        payload : offset,
    }
}
export const setAmendmentLoad = (load) => {
    return {
        type : SET_AMENDMENT_LOAD,
        payload : load,
    }
}
export const setAmendmentLanguage = (lang) => {
    return {
        type : SET_AMENDMENT_LANGUAGE,
        payload : lang,
    }
}


















export const fetchAmendmentListSuccess = (data,offsetVal,Load) => {
    return {
        type : FETCH_AMENDMENT_SUCCESS,
        payload : data,
        offset : offsetVal,
        loadMore : Load
    }
}
export const fetchAmendmentListFailure = (error) => {
    return {
        type : FETCH_AMENDMENT_FAILURE,
        payload : error
    }
}



export const fetchAmendmentList = (offset,languageType,signal) => {
    return  async ()=>{
        return new Promise((resolve, reject)=>{
            axios.get(`${HOST}amendments/?language_type=${languageType}&offset=${offset}`)
            .then((res)=>{
                resolve(res);
              
            }).catch((err)=>{
                reject(err)
            });
    
        })
    }
}



export const fetchSpecificAmendment = (id) => {
    
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}amendments/${id}/`)
        .then((res)=>{
            // console.log(res)
            resolve(res);
            
        }).catch((err)=>{
            reject(err)
        });

    })
   
}

