import { TOGGLE_LANGUAGE } from "./ToggleLanguageTypes"

const initialState = {
    currentLanguageType : 1
}

const toggleLanguageReducer = (state = initialState, action) =>{
    switch(action.type){
        case TOGGLE_LANGUAGE:
            return {
                ...state,
                currentLanguageType : state.currentLanguageType === 1? 2: 1,
            }
        default :
            return state;

    }
}

export default toggleLanguageReducer