import { postKnowledgeGraphReviewversion, patchKnowledgeGraphReviewversion } from '../Admin_APIs'
import React from 'react'
import TextEditor from '../../KnowledgeGraph/TextEditor';
import FullScreenContent from '../../MainContent/FullScreenContent'
import OldVersion from './OldVersion';
import CurrentVersion from './CurrentVersion';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button, TextField, Grid, Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: 'center',
    },
    highlight: {
        backgroundColor: '#B4D5FF',
    },
}));

const ReviewKnowledgeGraph = (props) => {
    const classes = useStyles();
    const history = useHistory()

    //////////////////////////////////////////////////////////////////////////////////////
    const [editable, SetEditable] = React.useState(false)
    const [text, setText] = React.useState()
    const [reviewer_comment, setReviewer_comment] = React.useState()
    const [state, setState] = React.useState({
        is_published: false,
        is_rejected: false
    })

    const [data, SetData] = React.useState({
        current_version: [],
        old_version: [],
    })

    //////////////////////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        postKnowledgeGraphReviewversion(props.location.state)
            .then((res) => {
                SetData({
                    current_version: res.data.current_version,
                    old_version: res.data.old_version,
                })
                setText(res.data.current_version.new_content)
            })
            .catch((err) => {
                // console.log(err);
            });
    }, [])

    //////////////////////////////////////////////////////////////////////////////////////
    const Reviewhandler = () => {
        delete data.current_version['content_object']
        data.current_version.new_content = text;
        data.current_version.is_published = state.is_published;
        data.current_version.is_rejected = state.is_rejected;
        data.current_version.reviewer_comment = reviewer_comment;

        if (reviewer_comment === undefined)
            alert('Reviewer Comment can not be null')
        else
            patchKnowledgeGraphReviewversion(data.current_version)
                .then((res) => {
                    // console.log("sucess")
                })
                .catch((err) => {
                    // console.log(err);
                });
        history.push('/admin/dashboard')
    }

    //////////////////////////////////////////////////////////////////////////////////////
    const FunctionButton = () => {
        return (
            <Grid className='text-center' item xs={12}>
                {editable ?
                    <>
                        <Button onClick={() => SetEditable(false)} className='mr-4' color="primary" variant="contained"><ArrowBackIcon /></Button>
                        <Button onClick={() => SetEditable(false)} className='mr-4' color="primary" variant="contained">Save Changes</Button>
                    </>
                    :
                    <>
                        {!(state.is_published || state.is_rejected) ?
                            <>
                                <Button className='mr-4' variant="contained" color="primary"
                                    onClick={() => history.push('/admin/dashboard')}><strong>Back</strong>
                                </Button>

                                <Button onClick={() => setState({
                                    ...state,
                                    is_published: true
                                })} className='mr-4' variant="contained" color="primary">approve</Button>

                                <Button onClick={() => setState({
                                    ...state,
                                    is_rejected: true
                                })} className='mr-4' variant="contained" color="primary">reject</Button>

                                <Button onClick={() =>
                                    history.push('/admin/dashboard')} className='mr-4' variant="contained" color="primary">review later</Button>
                            </>
                            : <>
                                <Button className='mr-4' variant="contained" color="primary" onClick={() => {
                                    setState({
                                        is_published: false,
                                        is_rejected: false
                                    })
                                }}>cancel</Button>
                                {
                                    state.is_published ?
                                        <Button className='mr-4' variant="contained"color="primary" onClick={Reviewhandler}> published</Button>
                                        :
                                        <Button className='mr-4' variant="contained"color="primary" onClick={Reviewhandler}> rejected</Button>
                                }
                            </>
                        }
                        <Button onClick={() => SetEditable(true)} className='mr-4' variant="contained" color="primary"><EditIcon /></Button>
                    </>
                }
            </Grid>
        );
    }

    //////////////////////////////////////////////////////////////////////////////////////


    return (
        <FullScreenContent>
            <div className={classes.root}>
                {!(state.is_published || state.is_rejected) ?

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <h3 className='text-center mt-4'>Published version</h3>
                            <hr />
                            <Paper className={classes.paper} >
                                <OldVersion old_version={data.old_version} />
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <h3 className='text-center mt-4'>Current version </h3>
                            <hr />
                            {editable ?
                                <Paper className={classes.paper} >
                                    <TextEditor value={text} onChange={setText} />
                                </Paper>
                                :
                                <Paper className={classes.paper} >
                                    <CurrentVersion current_version={text} old_version={data.old_version.new_content} />
                                </Paper>
                            }
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12}>
                            <h3 className='text-center mt-4'>current version </h3>
                            <hr />
                            {editable ?
                                <Paper className={classes.paper} >
                                    <TextEditor value={text} onChange={setText} />
                                </Paper>
                                :
                                <Paper className={classes.paper} >
                                    <CurrentVersion current_version={text} />
                                </Paper>
                            }
                        </Grid>
                        <TextField
                            fullWidth
                            label="Reviewer Comment *"
                            margin="normal"
                            variant="outlined"
                            value={reviewer_comment}
                            onChange={(e) => setReviewer_comment(e.target.value)}
                        />
                        <div className='text-center'>

                        </div>
                    </>
                }
            </div>
            <hr />
            <FunctionButton />
            <hr />
        </FullScreenContent>
    )
}

export default ReviewKnowledgeGraph
