import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    tableheadcell: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: '#0D0D77'
    },
    tablebodycell: {
        padding: theme.spacing(3),
        fontSize: 15,
        textAlign: 'center',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    div: {
        backgroundColor: "lightgreen",
        wordWrap: "break-word",
        whiteSpace: "normal"
    },
}));
const DataList = (props) => {
    console.log('DataList_props', props)
    const value = props.value
    const results = props.results
    const classes = useStyles();
    const history = useHistory();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        props.setOffset((value - 1) * 20)
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        setPage(1)
    }, [props.count])


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const TableData = (props) => {
        return (
            <>
                {
                    <TableBody >
                        <TableRow key={props.index} hover onClick={() => {
                            history.push('/admin/ReviewKnowledgeGraph', props.result)
                        }}>
                            <TableCell className={classes.tablebodycell} >
                                <Typography>
                                    {props.index + 1}
                                </Typography>
                            </TableCell>

                            <TableCell className={classes.tablebodycell}>
                                <Typography>
                                    <strong>
                                        {props.result.content_object.title}
                                    </strong>
                                </Typography>
                            </TableCell>

                            <TableCell className={classes.tablebodycell} >
                                <Typography>
                                    {props.result.edit_date ? new Date(props.result.edit_date).toLocaleString() : ''}
                                </Typography>
                            </TableCell>

                            <TableCell className={classes.tablebodycell} >
                                <Typography>
                                    {props.result.is_pending ?
                                        <Chip
                                            size="small"
                                            label="Pending"
                                            style={{ background: '#3d5afe', color: 'white' }}
                                        />
                                        : props.result.is_published ?
                                            <Chip
                                                size="small"
                                                label="Published"
                                                style={{ background: '#43a047', color: 'white' }}
                                            />
                                            : props.result.is_rejected ?
                                                <Chip
                                                    size="small"
                                                    label="Rejected"
                                                    style={{ background: '#e91e63', color: 'white' }}
                                                />
                                                : ''}
                                </Typography>
                            </TableCell>

                            <TableCell className={classes.tablebodycell} >
                                <Typography>
                                   <strong style={{color:'#3f51b5'}}>
                                    {props.result.current_version}
                                   </strong>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                }
            </>
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>
            <Paper>
                <TableContainer >
                    <Table value={value}>
                        <TableHead >
                            <TableRow>
                                <TableCell className={classes.tableheadcell}>Sr.</TableCell>
                                <TableCell className={classes.tableheadcell}>Title</TableCell>
                                <TableCell className={classes.tableheadcell}>Creation Date</TableCell>
                                <TableCell className={classes.tableheadcell}>Review Status</TableCell>
                                <TableCell className={classes.tableheadcell}>version</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            results.map((result, index) =>
                                value === '' ?
                                    <TableData result={result} index={index} />
                                    : value === 'article' ?
                                        <>
                                            {result.content_object.article_id ?
                                                <TableData result={result} index={index} /> : null
                                            }
                                        </>
                                        : value === 'amendment' ?
                                            <>
                                                {result.content_object.amendment_id ?
                                                    <TableData result={result} index={index} /> : null
                                                }
                                            </>
                                            : value === 'schedule' ?
                                                <>
                                                    {result.content_object.schedule_id ?
                                                        <TableData result={result} index={index} /> : null
                                                    }
                                                </>
                                                : value === 'law_schedule' ?
                                                    <>
                                                        {result.content_object.law ?
                                                            <TableData result={result} index={index} /> : null
                                                        }
                                                    </>
                                                    : value === 'section' ?
                                                        <>
                                                            {result.content_object.schedule_id ?
                                                                <TableData result={result} index={index} /> : null
                                                            }
                                                        </>
                                                        : null
                            )}
                    </Table>
                </TableContainer >
            </Paper>
            <Paper>
                <Typography className='mt-2'>
                    <Typography className='text-center'>Page: {page}</Typography>

                    {props.count > 20 ?
                        <>
                            <Pagination
                                size='large'
                                color="primary"
                                count={Math.ceil(props.count / 20)}
                                page={page}
                                onChange={handleChange}
                                showFirstButton
                                showLastButton />
                        </>
                        : null
                    }
                </Typography>
                <hr />
            </Paper>
        </>
    )
}

export default DataList

