import { FETCH_LAW_FAILURE, FETCH_LAW_SUCCESS } from "./FetchLawListTypes"

import {SET_LAW_DATA, SET_LAW_LOAD, SET_LAW_OFFSET} from "./FetchLawListTypes"


import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'

export const setLawData = (data) => {
    return {
        type : SET_LAW_DATA,
        payload : data,
    }
}

export const setLawOffset = (offset) => {
    return {
        type : SET_LAW_OFFSET,
        payload : offset,
    }
}

export const setLawLoad = (load) => {
    return {
        type : SET_LAW_LOAD,
        payload : load,
    }
}




export const fetchLawListSuccess = (data,offsetVal,Load) => {
    return {
        type : FETCH_LAW_SUCCESS,
        payload : data,
        offset : offsetVal,
        loadMore : Load
    }
}

export const fetchLawListFailure = (error) => {
    return {
        type : FETCH_LAW_FAILURE,
        payload : error
    }
}



export const fetchLawList = (offset) => {
   
    return  async (dispatch)=>{
        return new Promise((resolve, reject)=>{
            axios.get(`${HOST}law/?&offset=${offset}`,
            {
                headers:{
                    "Authorization" : `token ${getToken()}`
                }
            })
            .then((res)=>{
                resolve(res);
            }).catch((err)=>{
                reject(err)
            });
    
        })
    }
}

