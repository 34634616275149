import Cookies from 'js-cookie'


export const getLoginStatus =()=>{
    if(Cookies.get('logInStatus')==="true")
        return true;
    else
        return false;
}

export const getToken =()=>{
    return Cookies.get('token');
}

export const setLoginStatus =(info)=>{
    Cookies.set('logInStatus',info);
}

export const setToken =(token)=>{
    Cookies.set('token',token);
}

export const clearCookie =()=>{
    Cookies.remove('token');
    Cookies.remove('logInStatus');
    Cookies.remove('is_staff')
}

export const getUsername =()=>{
    return Cookies.get('username');
}

export const setUsername =(info)=>{
    Cookies.set('username',info);
}

export const getStaffStatus =()=>{
    if(Cookies.get('is_staff')==="true")
        return true;
    else
        return false;
}

export const setStaffStatus =(info)=>{
    Cookies.set('is_staff',info);
}

export const getUserEmail =()=>{
    return Cookies.get('useremail');
}
export const setUserEmail =(info)=>{
    Cookies.set('useremail',info);
}


export const getUserPicture =()=>{
    return Cookies.get('userpicture');
}

export const setUserPicture =(info)=>{
    Cookies.set('userpicture',info);
}


