export const FETCH_LAW_SUCCESS = 'fetchLawSuccess';
export const FETCH_LAW_FAILURE = 'fetchLawFailure';




export const SET_LAW_DATA = 'setLawData';
export const SET_LAW_OFFSET = 'setLawOffset';
export const SET_LAW_LOAD= 'setLawLoad';
// export const SET_LAW_LANGUAGE= 'setLawLanguage';



