import React from 'react'
// import MainContentSection from '../MainContent/MainContentSection'
import { TextField, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik'

import { loginUser } from '../../Redux/APIs/Login/LoginActions'
import { googleLoginUser } from '../../Redux/APIs/GoogleLogin/GoogleLoginActions'

import { googleProvider } from '../../config/firebaseAuthenticationMethods';
import { socialMediaAuth } from '../../config/firebaseAuthentication';

// import firebase from '../../config/firebase-config'

// import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';

import { connect } from 'react-redux'

import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'

import { Redirect, useHistory } from 'react-router-dom';

import { setToken, setLoginStatus, getLoginStatus, setUserEmail, setUsername, setStaffStatus } from '../../config/cookiesInfo'
import FullScreenContent from '../MainContent/FullScreenContent';

import { firebaseAnalytics } from '../../config/firebase-config'


const useStyles = makeStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.primary.main,//color: 'red',

        },
        '& .MuiOutlinedInput-root': {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 45,
            },
            '& fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,


            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                //borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,
            },
        },
    },

    boxContainer: {
        borderRadius: 7,
        backgroundColor: 'white',
        // border:'3px solid black',
        margin: 'auto',
        maxWidth: 380,

        // minHeight:410,

        [theme.breakpoints.up('xs')]: {
            width: '85%',
            paddingBottom: 50,

        },
        [theme.breakpoints.down('sm')]: {
            // minHeight:390,
            paddingBottom: 30,

        },

    },
    content: {
        width: '100%',
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 25,
        }
    },
    container: {
        align: 'center',
    },
    formStyle: {
        display: 'grid',
        margin: 'auto',
        maxWidth: 280,
        [theme.breakpoints.up('xs')]: {
            width: '85%',
        }
    },
    txtemail: {

    },
    txtPassword: {
        marginTop: 15,
    },
    loginButton: {
        marginTop: 20,
    },
    forgotPassword: {
        fontSize: 13,
        marginTop: 9,
        marginBottom: 0,
        cursor: 'pointer'
    },
    signUp: {
        marginTop: 9, //comment this in case of forgot password
        fontSize: 13,
        // marginTop:5, //uncomment incase of forgotpassword
        cursor: 'pointer'
    },
    googleButton: {
        marginTop: 5 //comment this in case of forgot password
        // marginTop:15, //uncomment incase of forgotpassword
    },
    loginText: {
        fontSize: 25,
        margin: 'auto',
        fontWeight: 600,
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            marginTop: -10,
        }

    },
    errorMessage: {
        color: 'red',
        marginBottom: 0,
    }
}));


const initialValues = {
    email: '',
    password: '',
};



const validate = values => {
    let errors = {};

    if (!values.email) {
        errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email format'
    }

    if (!values.password) {
        errors.password = 'Required'
    }
    return errors;
}



function Login(props) {
    const classes = useStyles();
    const history = useHistory();
    // console.log('Login_props', props)

    const onSubmit = values => {
        // console.log(values);

        loginUser(values)
            .then((res) => {
                // console.log('loginUser_res', res);

                if (res.data.success !== true) {
                    alert('Login unsuccessful ' + res.data.status);
                    // console.log(res);
                } else {
                    console.log('Successfully Logged in/');
                    // console.log(res);
                    // console.log('Access Token : ',res.data.data.access_token);
                    // alert('LogIn Successful '+ res.data.status);

                    setToken(res.data.data.access_token);
                    setLoginStatus(true);
                    setUsername(res.data.data.user.name);
                    setUserEmail(res.data.data.user.email);
                    setStaffStatus(res.data.data.user.is_staff)

                    // setUserPicture(res.data.data.user.picture);

                    // history.push('/');
                    firebaseAnalytics.logEvent(`${res.data.data.user.email}_loggedIn`);



                    if (props.location && props.location.state){
                        // console.log('if_props==>',props)
                        history.push(props.location.state.pathToGo);
                    }
                    else{
                        // console.log('else_props==>',props)
                        if (props.pathToGo) {
                            // console.log('pathToGo_if')
                            history.push(props.pathToGo)    
                        }
                        else{
                            // console.log('pathToGo_else')
                                // history.push(props.location.location.pathname,props.location.location.state);
                                try{
                                    history.push(props.location.location.pathname,props.location.location.state);
                                }
                                catch{
                                    history.push('/')
                                }
                            }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });


    // React.useEffect(()=>{
    //     // props.setMenuItems([],4);
    // },[props])







    const googleOnclick = async (provider) => {
        // console.log('provider===>', provider)

        try {
            const res = await socialMediaAuth(provider);
            // console.log('googleOnclick_res', res);

            if (res.additionalUserInfo && res.additionalUserInfo.profile) {
                const user = res.additionalUserInfo.profile;
                let formData = new FormData();

                formData.append('email', user.email);
                formData.append('id', user.id);
                formData.append('type', 'google');
                formData.append('name', user.name);
                formData.append('dob', '');
                formData.append('gender', '');
                formData.append('file', user.picture);


                googleLoginUser(formData)
                    .then((res) => {

                        // console.log('googleLoginUser_res = ', res);
                        //redirect to specific page //setToken and status

                        if (res.data.success !== true) {
                            alert('Login unsuccessful ' + res.data.status);
                        } else {
                            console.log('Successfully Logged in/');
                            // console.log('Successfully Logged in access_token', res.data.data.access_token);
                            setToken(res.data.data.access_token);
                            setLoginStatus(true);
                            //res.data.data.user.email,name,picture
                            setUsername(res.data.data.user.name);
                            setUserEmail(res.data.data.user.email);
                            setStaffStatus(res.data.data.user.is_staff);
                            // setUserPicture(res.data.data.user.picture);

                            // history.push('/');
                            firebaseAnalytics.logEvent(`${res.data.data.user.email}_loggedIn`);


                            if (props.location && props.location.state){
                                history.push(props.location.state.pathToGo);
                            }
                            else{
                                // console.log('else_props==>',props)
                                if (props.pathToGo) {
                                    // console.log('pathToGo_if')
                                    history.push(props.pathToGo)    
                                }
                                else{
                                    // console.log('pathToGo_else')
                                    // history.push(props.location.location.pathname,props.location.location.state);
                                    try{
                                        history.push(props.location.location.pathname,props.location.location.state);
                                    }
                                    catch{
                                        history.push('/')
                                    }
                                }
                            }
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })


            } else {
                console.log('Login unsuccessfull');
                alert('Login unsuccessfull');
            }


        } catch (err) {
            console.log(err);
        }



        // console.log(user);   
    }


    return (
        <>
            {
                getLoginStatus() ?
                    <Redirect to={{
                        pathname: '/',
                    }} /> :

                    <FullScreenContent>
                        <div className={classes.container}>
                            <div className={classes.content}>

                                <Box style={{ paddingTop: 20 }} className={classes.boxContainer}>

                                    <form className={classes.formStyle}
                                        noValidate
                                        // onSubmit={onFormSubmit}
                                        onSubmit={formik.handleSubmit}
                                        autoComplete="off">

                                        <h5 className={classes.loginText}>Login</h5>
                                        <TextField
                                            className={classes.txtemail}
                                            classes={{
                                                root: classes.root
                                            }}

                                            // value={email} 
                                            // onChange={(e)=>setEmail(e.target.value)} 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            id="email" label="Email" type="email" name='email' variant="outlined" />
                                        {formik.touched.email && formik.errors.email ? <p className={classes.errorMessage}>{formik.errors.email}</p> : null}

                                        <TextField
                                            // value={password}
                                            // onChange={(e)=>setPassword(e.target.value)}
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            id="password"
                                            label="Password"
                                            type="password"
                                            name='password'
                                            variant="outlined"
                                            classes={{
                                                root: classes.root
                                            }}
                                            className={classes.txtPassword}
                                        />
                                        {formik.touched.password && formik.errors.password ? <p className={classes.errorMessage} style={{ marginBottom: -7 }}>{formik.errors.password}</p> : null}

                                        <Button
                                            className={classes.loginButton}
                                            type='submit'
                                            // onClick={()=>alert(""+email+"-"+password)}
                                            variant="contained"
                                            color="primary"
                                            disableElevation>
                                            Login
                                        </Button>

                                        {/* <p className={classes.forgotPassword}>Forgot Password?</p> */}
                                        <p className={classes.signUp}><Link to={{
                                            pathname: "/signup",
                                            state: {
                                                pathToGo: props.location && props.location.state ? props.location.state.pathToGo : props.pathToGo ? props.pathToGo : props.location.location

                                                //  pathToGo: props.pathToGo?props.pathToGo:'/' 
                                            }
                                        }}>Don't have an account? Sign Up</Link></p>

                                        {/* <Button 
                                        className={classes.googleButton}
                                        onClick={()=>googleOnclick(googleProvider)}
                                        variant="contained" 
                                        // color="primary" 
                                        disableElevation>
                                        Sign In with Google
                                </Button> */}
                                    </form>

                                    <div className={classes.formStyle}>
                                        <Button
                                            className={classes.googleButton}
                                            onClick={() => googleOnclick(googleProvider)}
                                            variant="contained"
                                            color="primary"
                                            disableElevation>
                                            Sign In with Google
                                        </Button>
                                    </div>

                                </Box>
                            </div>
                        </div>


                    </FullScreenContent>
            }
        </>
    )
}

// export default Login


const mapDispatchToProps = (dispatch) => {
    return {
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Login)