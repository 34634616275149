import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchChapters = (id, part) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}chapter/?law=${id}&part=${part}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}
