import { FETCH_SCHEDULE_SUCCESS,FETCH_SCHEDULE_FAILURE } from "./FetchScheduleListTypes"

const initialState = {
    data : [],
    error : ''
}

const fetchScheduleListReducer = (state = initialState, action) =>{

    switch(action.type){
        case FETCH_SCHEDULE_FAILURE:
            return {
                data : [],
                error : action.error
            }
        case FETCH_SCHEDULE_SUCCESS:
            return {
                data : action.payload,
                error : ''
            }
        default :
            return state;

    }
}

export default fetchScheduleListReducer