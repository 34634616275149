import React, { useEffect } from 'react'
import { searchList, searchListWithoutAuthentication } from '../../Redux/APIs/Search/SearchActions'
// import MainContentSection from '../MainContent/MainContentSection';
import useState from 'react-usestateref'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import parse from 'html-react-parser'

import { getLoginStatus } from '../../config/cookiesInfo'
// import Login from '../../components/Login/Login';

import { connect } from 'react-redux'

import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions';
import FullScreenContent from '../MainContent/FullScreenContent';
import store from '../../Redux/Store/store'
import { logoutUser } from '../../config/firebaseAuthentication';


const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto'

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    noDataDiv: {
        fontSize: 20,
        fontWeight: 600

    }

}));


function SearchResult(props) {
    const currentlanguage_type = store.getState().toggleLanguage.currentLanguageType;
    const history = useHistory();
    // let {fetchJudgementList,fetchJudgementListSuccess,setJudgementData,setJudgementLoad} = props;
    const classes = useStyles();




    const [data, setData, refData] = useState([]);

    // const [offset,setOffset,refOffset] = useState(judgementList.length===0?0:judgementList.length-20);
    // const [load,setLoad,refLoad] = useState(judgementLoad);
    const [spinner, setSpinner, refSpinner] = useState(false);


    let text = props.match.params.text;
    let category = props.match.params.category;




    let paths = {
        articles_index: '/constitution/parts/chapters/articles/',
        amendments_index: '/constitution/amendments/',
        schedules_index: '/constitution/schedules/',
        laws_index: '/laws/individualLaw',
        debates_index: '/resource/constituentAssemblyDebates/',
        judgements_index: '/resource/supremeCourtJudgements/',

    };



    // useEffect(()=>{
    //     // props.setMenuItems([],2);
    //     // props.displayLeftSidebar(f);
    // },[])

    useEffect(() => {
        setData([])
        if (getLoginStatus() && (category === "laws_index" || category === "debates_index" || category === "judgements_index")) {
            console.log('hhhhhh')
            setSpinner(true);
            searchList(text, category)
                .then((res) => {
                    setSpinner(false);
                    setData(res.data.hits)
                })
                .catch((err) => {
                    setSpinner(false);
                    console.log(err);
                    if (err.response.status === 401) {
                        logoutUser()
                        history.push("/")
                    }
                })
        } else if (category === "articles_index" || category === "amendments_index" || category === "schedules_index") {
            setSpinner(true);
            if (getLoginStatus()) {
                searchList(text, category)
                    .then((res) => {
                        console.log('res', res)
                        setSpinner(false);
                        setData(res.data.hits)
                    })
                    .catch((err) => {
                        setSpinner(false);
                        console.log(err);
                        if (err.response.status === 401) {
                            logoutUser()
                            history.push("/")
                        }
                    })
            }
            else {
                searchListWithoutAuthentication(text, category)
                    .then((res) => {
                        console.log('res', res)
                        setSpinner(false);
                        setData(res.data.hits)
                    })
                    .catch((err) => {
                        setSpinner(false);
                        console.log(err);
                    })
            }

        }



    }, [text, category])


    return (
        <>
            {
                // !getLoginStatus()?
                // <Login pathToGo = {`/searchResult/${text}/${category}`}/>:

                !getLoginStatus() && (category === "laws_index" || category === "debates_index" || category === "judgements_index") ?
                    <Redirect to={{
                        pathname: '/login',
                        state: {
                            pathToGo: `/searchResult/${text}/${category}`
                        }
                    }} /> :
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>Search Results</h2>
                            <hr className={classes.divider} />
                            <div className={classes.content}>
                                {
                                    refData.current.length === 0 ?//refData.current.length===0?
                                        spinner ? <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                            <div style={{ display: 'flex', justifyContent: 'center' }}><p className={classes.noDataDiv}>No data Found</p></div> :
                                        <List>
                                            {
                                                refData.current.map((info, index) => {
                                                    // console.log('SerchResult_info', info)
                                                    return (
                                                        info._source.language_type === currentlanguage_type || info._source.language_type === undefined ?
                                                            <ListItem style={{ cursor: 'pointer' }} key={index}

                                                                onClick={() => {

                                                                    if (("" + info._index === "laws_index") || ("" + info._index === "dev-laws_index")) {
                                                                        history.push("" + paths[info._index.replace('dev-', '')],
                                                                            {
                                                                                title: info._source.title,
                                                                                id: info._source.id,
                                                                                has_part: info._source.has_part,
                                                                                has_chapter: info._source.has_chapter,
                                                                                has_schedule: false,
                                                                                has_preamble: info._source.has_preamble,
                                                                            }
                                                                        )
                                                                    }
                                                                    else {
                                                                        history.push("" + paths[info._index.replace('dev-', '')] + "" + info._id, info._source);
                                                                        // console.log('"" + paths[info._index] + "" + info._id,info._source.article_id',"" + paths[info._index] + "" + info._id,info._source.article_id)
                                                                    }
                                                                }}>
                                                                <h6>
                                                                    <b>
                                                                        {
                                                                            parse(info._source.title)
                                                                        }
                                                                        {
                                                                            info._source.description && info._source.description.length !== 0 ?
                                                                                " - " +
                                                                                parse(info._source.description) : ''
                                                                        }
                                                                    </b>
                                                                </h6>
                                                            </ListItem>
                                                            : null
                                                    )
                                                })
                                            }
                                        </List>
                                }

                            </div>
                        </div>
                    </FullScreenContent>
            }
        </>
    )
}

// export default SearchResult



const mapDispatchToProps = (dispatch) => {
    return {
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
        displayLeftSidebar: (val) => dispatch(displayLeftSidebar(val)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(SearchResult)
