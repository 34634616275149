import React from 'react'
import { postContributeKnowledgeGraph } from '../../Redux/APIs/KnowledgeGraph/KnowledgeGraphAction'
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import TextEditor from './TextEditor'
import SaveIcon from '@material-ui/icons/Save';
import { Typography, Button, Grid, CardContent } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    btn: {
        textAlign: "center",
        padding: theme.spacing(2),
        color: theme.palette.text.secondary
    },
    alert: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            textAlign: 'end',

        }
    },
}));

const EditContributeKnowledgeGraph = (props) => {
    // console.log('EditContributeKnowledgeGraph_props=>', props)
    const classes = useStyles();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [newcontent, setNewContent] = React.useState(props.data.new_content)
    const [result, setResult] = React.useState(null)
    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    const onSubmit = () => {
        const props_data = props.data;
        props_data.new_content = newcontent;
        // console.log('onSubmit_sendData=>', props_data)
        if (newcontent.length >= 1000) {
            postContributeKnowledgeGraph(props_data)
                .then((res) => {
                    // console.log('postContributeKnowledgeGraph_res', res.data);
                    setResult(res.data.success)
                    props.setClose(false)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            alert("make sure the input characters atleat 1000 characters")
        }
    };

    const onClose = () => {
        props.setEditor(false)
        props.setClose(true)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (

        <>
            {result != null ?
                <>
                    <Typography >
                        <Alert severity="info">{result} — just now!</Alert>
                    </Typography>

                    <hr />
                    <Grid item className={classes.btn} >
                        <Button className='mt-4' variant="contained" color="primary" onClick={onClose} ><strong>Close</strong></Button>
                    </Grid>

                </>
                :
                <>
                    <Typography>*Write your Contribute atleast 1000 characters.</Typography>
                    <Typography component={CardContent}>
                        <TextEditor value={props.data.new_content}
                            onChange={setNewContent}
                        />
                    </Typography>
                    <hr />
                    <Grid container direction="column"
                        alignItems="center"
                    >
                        <Grid item xs={6} sm={6}  >
                            <Button onClick={onSubmit} variant="contained" color="primary"><SaveIcon /><strong>Save</strong></Button>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default EditContributeKnowledgeGraph

