import { fetchSpecificKnowledgeGraph } from '../../Redux/APIs/KnowledgeGraph/KnowledgeGraphAction'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import FullScreenContent from '../../components/MainContent/FullScreenContent';
import EditContributeKnowledgeGraph from '../../components/KnowledgeGraph/EditContributeKnowledgeGraph';

import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Container,
    Button,
    Grid,
    Chip,
} from '@material-ui/core';
import { logoutUser } from '../../config/firebaseAuthentication';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    paper: {
        padding: theme.spacing(2),
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    usersroot: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    edit: {
        padding: theme.spacing(2),
        textAlign: "end",
        color: theme.palette.text.secondary
    },
    back: {
        padding: theme.spacing(2),
        textAlign: "start",
        color: theme.palette.text.secondary
    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        color: 'blue',
    },
    spinnerContainer: {
        display: "flex"
    },
}));

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const KnowledgeGraph = (props) => {
    // console.log('KnowledgeGraph_props', props)
    const classes = useStyles();
    const history = useHistory();

    const [result, setResult] = React.useState({
        contributers_list: [],
        title: '',
        content: '',
        back: '',
        version: null,
        isloaded: false,
    });
    // console.log('Result==>', result)
    const [editor, setEditor] = React.useState(false)
    const [close, setClose] = React.useState(true)
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    React.useEffect(() => {
        fetchSpecificKnowledgeGraph(props.match.url)
            .then((res) => {
                // console.log('fetchSpecificKnowledgeGraph_res', res.data);
                setResult({
                    contributers_list: res.data.contributers,
                    title: res.data.title,
                    content: res.data.content,
                    back: res.data.back,
                    version: res.data.version,
                    isloaded: true
                })
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 401) {
                    logoutUser()
                    history.push("/")
                }
            });
    }, [props])
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // console.log('result.contributers_list.length', result.contributers_list)
    const ContributersList = () => {
        const icon = <PersonIcon />
        return (
            <>
                <h6> <strong className='mt-4 mb-4'>Contributers :-</strong></h6>
                <div component="ul" className={classes.usersroot}>
                    {
                        result.contributers_list.length ?
                            <>
                                {result.contributers_list.map((data, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {data === null ?
                                                null
                                                :
                                                <li >
                                                    <Chip
                                                        icon={icon}
                                                        label={data}
                                                        className={classes.chip}
                                                    />
                                                </li>
                                            }
                                        </React.Fragment>
                                    );
                                })}
                            </>
                            : null
                    }
                </div>
            </>
        );
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <FullScreenContent>
            <Container className="text-center">
                <h2 className={classes.heading}>{result.title}</h2>
                <hr className={classes.divider} />
            </Container>

            <Container>
                {
                    result.isloaded ?

                        <Card>
                            <Grid container spacing={3}>
                                <Grid item xs={6} sm={6} className={classes.back} >
                                    {
                                        !editor ?
                                            <Button variant="contained" color="primary"
                                                onClick={() => history.push(result.back)}><strong><ArrowBackIcon /></strong>
                                            </Button>
                                            : null
                                    }
                                </Grid>
                                <Grid item xs={6} sm={6} className={classes.edit} >
                                    {
                                        !editor ?
                                            <>
                                                <Button variant="contained"
                                                    color="primary"
                                                    onClick={() => setEditor(true)} ><strong><EditIcon /></strong>
                                                </Button>
                                            </>
                                            : editor & close ?
                                                <>
                                                    <Button variant="contained"
                                                        color="primary"
                                                        onClick={() => setEditor(false)} ><strong><CloseSharpIcon /></strong>
                                                    </Button>
                                                </>
                                                : null
                                    }
                                </Grid>
                            </Grid>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                </Typography>
                                <Grid className={classes.paper} item xs={12}>
                                    {
                                        editor ?
                                            <EditContributeKnowledgeGraph
                                                setEditor={setEditor}
                                                setClose={setClose}
                                                data={{
                                                    pathname: props.match.url,
                                                    new_content: result.content,
                                                    current_version: result.version
                                                }}
                                            />
                                            :
                                            <div dangerouslySetInnerHTML={{ __html: result.content }} />
                                    }
                                </Grid>
                                {result.contributers_list ?
                                    <>
                                        {
                                            !editor ?
                                                <>
                                                    <hr />
                                                    <ContributersList />
                                                </>
                                                : null
                                        }
                                    </>
                                    : null
                                }
                            </CardContent>
                        </Card>
                        :
                        <>
                            <div className={classes.container}>
                                <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>
                            </div>
                        </>

                }
            </Container>
        </FullScreenContent>
    )
}

export default KnowledgeGraph

