import {HOST} from '../../../config/config'
import axios from 'axios'

export const googleLoginUser = (formData) => {

    return new Promise((resolve, reject)=>{
        // console.log('form data ====',formData);

        axios.post(`${HOST}social_login/`,formData)   //axios.post(`${HOST}socialLogin/`,formData)
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
   
}
