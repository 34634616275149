import {HOST} from '../../../config/config'
import axios from 'axios'

export const loginUser = (data) => {
    return new Promise((resolve, reject)=>{

        axios.post(`${HOST}login/`,data)
        .then((res)=>{
            // console.log(res.data.results)
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
   
}