import React from 'react'
import MainContentSection from '../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import {setMenuItems} from '../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { firebaseAnalytics } from '../config/firebase-config'


const useStyles = makeStyles((theme) => ({
    heading:{
        textAlign:'center',
        paddingTop:30,
        fontWeight:600,

        [theme.breakpoints.up('xs')]:{
            paddingTop:15,
            fontSize:22,
            marginBottom:-7,
        },
        [theme.breakpoints.up('sm')]:{
            paddingTop:25,
            fontSize:24,
        },
        [theme.breakpoints.up('md')]:{
            paddingTop:31,
        },
        [theme.breakpoints.up('lg')]:{
            paddingTop:35,
            fontSize:26,
        }
    },
    divider:{
        width:'90%',
        align:'center',
        backgroundColor:'black'
    },
    content:{ 
        width:'85%',
        margin:'auto',
        paddingTop:20,

        [theme.breakpoints.up('xs')]:{
            paddingTop:0,
        },
        [theme.breakpoints.up('sm')]:{
            paddingTop:2,
        }
       
    },
    container:{
        align:'center',
        
    },
    textStyle : {
        fontSize:18,
        marginTop:5,

        [theme.breakpoints.up('xs')]:{
            fontSize:13,
        },
        [theme.breakpoints.up('sm')]:{
            fontSize:15.5,
        },
        [theme.breakpoints.up('lg')]:{
            fontSize:17,
        }
    }

}));





function Preamble(props) {

    const classes = useStyles();

    React.useEffect(()=>{
        window.scrollTo(0,0);
        props.setMenuItems([],1);
        // firebaseAnalytics.logEvent(`PreamblePage_visited`);
    },[])





    return (
        <>
        {/* <LeftSidebar/> */}
            {
                props.currentLanguageType===1?
                (
                    <MainContentSection>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>Preamble</h2>
                            <hr className={classes.divider}/>
                            <div className={classes.content}>

                            <p className={classes.textStyle}>
                                <b>WE, THE PEOPLE OF INDIA,</b> having solemnly resolved to constitute India into a <b>SOVEREIGN SOCIALIST SECULAR DEMOCRATIC REPUBLIC</b> and to secure to all its citizens:
                            </p>
                            <p className={classes.textStyle}>
                                <b>JUSTICE,</b> social, economic and political;
                            </p>
                            <p className={classes.textStyle}>
                                <b>LIBERTY</b> of thought, expression, belief, faith and worship;
                            </p>
                            <p className={classes.textStyle}>
                                <b>EQUALITY</b> of status and of opportunity,
                            and to promote among them all,
                            </p>
                            <p className={classes.textStyle}>
                                <b>FRATERNITY</b> assuring the dignity of the individual and the unity and integrity of the Nation;
                            </p>
                            <p className={classes.textStyle}>
                                <b>IN OUR CONSTITUENT ASSEMBLY</b> this 26th day of November 1949, do <b>HEREBY ADOPT, ENACT AND GIVE TO OURSELVES THIS CONSTITUTION.</b>
                            </p>
                            </div>
                        </div>
                    </MainContentSection>
                ):
                (
                    <MainContentSection>
                    <div className={classes.container}>
                        <h2 className={classes.heading}>Preamble</h2>
                        <hr className={classes.divider}/>
                        <div className={classes.content}>

                        
                        <p className={classes.textStyle}>
                        हम, भारत के लोग, भारत को एक <b>संपूर्ण प्रभुत्वसंपन्न, समाजवादी ,पंथनिरपेक्ष लोकतंत्रात्मक गणराज्य</b>  बनाने के लिए, तथा उसके समस्त नागरिकों को:
                        </p>

                        <p className={classes.textStyle}>
                        सामाजिक, आर्थिक और राजनीतिक <b>न्याय,</b>
                        </p>

                        <p className={classes.textStyle}>
                        विचार, अभिव्यक्ति, विश्वास, धर्म और उपासना की <b>स्वतंत्रता,</b> 
                        </p>

                        <p className={classes.textStyle}>
                        प्रतिष्ठा और अवसर की <b>समता</b>, प्राप्त कराने के लिए,
                        </p>

                        <p className={classes.textStyle}>
                        तथा उन सब में,
                        </p>

                        <p className={classes.textStyle}>
                        व्यक्ति की गरिमा और राष्ट्र की एकता और अखण्डता सुनिश्चित कराने वाली, <b>बन्धुता</b> बढ़ाने के लिए,
                        </p>

                        <p className={classes.textStyle}>
                        दृढ़ संकल्पित होकर अपनी संविधानसभा में आज तारीख 26 नवम्बर 1949 ईस्वी (मिति मार्गशीर्ष शुक्ल सप्तमी, संवत दो हजार छह विक्रमी) को एतद् द्वारा इस संविधान को अंगीकृत, अधिनियमित और आत्मार्पित करते हैं।
                        </p>

                        </div>
                    </div>
                </MainContentSection>
                )
            }
            
        </>
    )
}

const mapStateToProps = (state) =>{
    return {
        currentLanguageType : state.toggleLanguage.currentLanguageType
    }
}
 
const mapDispatchToProps = (dispatch) =>{
    return {
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
    }
}
 
// export default TopMenubar

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preamble)








// export default Preamble
