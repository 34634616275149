import React from 'react';
import FullScreenContent from '../../components/MainContent/FullScreenContent';

import { Box } from '@material-ui/core';

const PrivacyPolicy = () => {
    return (
        <FullScreenContent>
            <div style={{}}>
                <div style={{ padding: '10px' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h1>Privacy Policy</h1>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <div className="zrow">
                            <p>This Privacy Policy outlines how Artdex & Cognoscis Technologies LLP ("we," "us," or "our") collects, uses, discloses, and safeguards the information you provide when using the "Constitution of India" mobile application ("the App"). We are committed to protecting your privacy and ensuring the security of your personal information. By using the App, you consent to the practices described in this policy.</p>
                        </div>
                        <section>
                            <div>

                                <div className="zrow">
                                    <h3>Information We Collect</h3>
                                    <p><strong>Personal Information:</strong> We do not collect any personally identifiable information such as your name, address, email, or phone number.</p>
                                    <p><strong>Usage Information:</strong> We may collect non-personal information about how you use the App, such as the features you access, the time spent on different sections, and interactions within the App. This data is used to improve the user experience and optimize our services.</p>
                                    <p><strong>Device Information:</strong> We may collect technical information about your device, including its operating system, unique device identifiers, and mobile network information. This information helps us diagnose technical issues and improve compatibility.</p>
                                </div>

                                <div className="zrow">
                                    <h3>How We Use Your Information</h3>
                                    <p><strong>App Content and Features:</strong> The "Constitution of India" app provides information about various aspects of the Indian Constitution, including the Preamble, Parts, Amendments, Schedules, Constituent assembly debates, and Supreme court judgments. The data we collect is used to enhance and personalize your experience within the app by tailoring content to user preferences.</p>
                                    <p><strong>App Improvement:</strong> The data we collect is primarily used to analyze usage patterns and enhance the App's functionality, content, and user experience.</p>
                                    <p><strong>Analytics:</strong> We may use third-party analytics tools to gather and analyze non-identifiable usage data. These tools help us understand user behavior and preferences, enabling us to make informed decisions about app enhancements.</p>
                                </div>

                                <div className="zrow">
                                    <h3>Data Sharing and Disclosure</h3>
                                    <p><strong>Third-Party Services:</strong> We may engage third-party service providers to assist with analytics, technical support, and other operational purposes. These service providers are contractually obligated to maintain the confidentiality and security of your information.</p>
                                    <p><strong>Legal Requirements:</strong> We may disclose your information if required by law, legal process, or government request. We will also do so to protect our rights, privacy, safety, or property, as well as those of our users.</p>
                                </div>

                                <div className="zrow">
                                    <h3>Data Security</h3>
                                    <p>We take reasonable measures to protect the information you provide to us from unauthorized access, use, disclosure, or alteration. However, no method of transmitting data over the internet or storing electronic data is completely secure, so we cannot guarantee its absolute security.</p>
                                </div>

                                <div className="zrow">

                                    <h3>Children's Privacy</h3>
                                    <p>We do not knowingly collect or solicit personal information from children. If you believe that a child under 13 may have provided us with personal information, please contact us immediately at <a href="mailto:contact@artdexandcognoscis.com">contact@artdexandcognoscis.com</a> and we will promptly delete that information.</p>
                                </div>

                                <div className="zrow">
                                    <h3>Changes to Privacy Policy</h3>
                                    <p>We may update this Privacy Policy as needed. Any changes will be effective when we post the revised policy on the App. Your continued use of the App following the posting of changes indicates your acceptance of those changes.</p>
                                </div>

                                <div className="zrow">
                                    <h3>Contact Us</h3>
                                    <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:contact@artdexandcognoscis.com">contact@artdexandcognoscis.com</a>.</p>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </FullScreenContent>
    )
}

export default PrivacyPolicy
