import React from 'react'
// import LeftSidebar from '../../components/SidebarSection/LeftSidebar'
import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { connect } from 'react-redux'
import Login from '../../components/Login/Login'

import {getLoginStatus} from '../../config/cookiesInfo'

import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions';
import { useHistory } from 'react-router-dom'

function LawLink(props) {

    const history = useHistory();

    const moveTo = ()=>{
        history.push('/laws')
    }


    return (
        <>
        {
            getLoginStatus()?
                moveTo()
            :   <Login pathToGo = {'/laws'}/>
        }
        </>


    )
}

// export default ResourcePage

const mapDispatchToProps = (dispatch) =>{
    return {
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
        displayLeftSidebar : (val) => dispatch(displayLeftSidebar(val)),
    }
}

export default connect(
    null,
    mapDispatchToProps
  )(LawLink)




