import React from 'react'
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Box, } from '@material-ui/core';
import { makeStyles, } from "@material-ui/core/styles";

import './MainContentSection.css'

// const drawerWidth = 240;



const useStyles = makeStyles((theme) => ({

    containerStyle:{
      
        [theme.breakpoints.down('md')]:{
            position:'relative',
            right:0,
            padding:0,
            marginLeft:0,
        },
        [theme.breakpoints.up('md')]:{
            position:'relative',
            right:0,
            width:`calc(100% - ${270}px)`,
            left:270,
            padding:0,
            marginLeft:0,
        },
        [theme.breakpoints.up('lg')]:{
            width:`calc(100% - ${280}px)`,
            left:280,
        },
       
    }

}));

function MainContentSection(props) {
    // console.log('MainContentSection_props',props)
    const classes = useStyles();
//   const theme = useTheme();FFFFFF #f4f5f7
    return (

    <React.Fragment>
        <CssBaseline />
            <Container className={`${classes.containerStyle} mainContentStyle`} style={{maxWidth:"100%"}}>
            {/* <Box  color="text.primary" style={{backgroundColor:'#cfe8fc',minHeight:'100vh',paddingBottom:'50px'}}> */}
            <Box  color="text.primary" style={{backgroundColor:'#FFFFFF',minHeight:'100vh',paddingBottom:'50px'}}>
            {props.children}
            </Box>
                
            </Container>
      </React.Fragment>

    )
}

export default MainContentSection


















//     <React.Fragment>
    //     <CssBaseline />
    //         <Container maxWidth="lg">

    //         <Box  color="text.primary" style={{backgroundColor:'#cfe8fc',minHeight:'100vh',paddingBottom:'50px'}}>
    //         {props.children}
    //         </Box>
                
    //         </Container>
    //   </React.Fragment>
