import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {getLoginStatus} from '../../config/cookiesInfo'

function ProtectedRoute({component:Component,...rest}) {
    // console.log('rest==>',rest)
    // console.log('props==>')
    return (
        <Route {...rest} 
        render={
                (props) => {
                    if(getLoginStatus())
                        return <Component {...rest} {...props} />;
                    else 
                        return <Redirect to={{
                            pathname:'/login',...props
                        }} />
                    }
                }
        />
    )
}

export default ProtectedRoute
