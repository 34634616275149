// import {FETCH_SCHEDULE_SUCCESS,FETCH_SCHEDULE_FAILURE} from "./FetchScheduleListTypes"
// import store from '../../Store/store'
import {HOST} from '../../../config/config'
import axios from 'axios'



export const fetchArticleList = (offset,languageType,partId,chapterId) => {
    return  async (dispatch)=>{
        return new Promise((resolve, reject)=>{
            axios.get(`${HOST}article/?limit=100&offset=${offset}&language_type=${languageType}&part_id=${partId}&chapter_id=${chapterId}`)
            .then((res)=>{
                // console.log(res.data.results)
                resolve(res);
            }).catch((err)=>{
                reject(err)
                // console.log(err);
            });
    
        })
    }
}




export const fetchSpecificArticle = (id) => {
    
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}article/${id}/`)
        .then((res)=>{
            resolve(res);
            
        }).catch((err)=>{
            reject(err)
        });

    })
   
}


// export const fetchScheduleListSuccess = (data) => {
//     return {
//         type : FETCH_SCHEDULE_SUCCESS,
//         payload : data
//     }
// }
// export const fetchScheduleListFailure = (error) => {
//     return {
//         type : FETCH_SCHEDULE_FAILURE,
//         payload : error
//     }
// }

