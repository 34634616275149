import React from 'react'
import useState from 'react-usestateref'
import Snackbar from '@material-ui/core/Snackbar';
import {Tooltip} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//     snackbar: {
//         width:50,
//         maxWidth:50,
//     }
// }));


function TooltipLinkShare(props) {
    const [open, setOpen, refOpen] = useState(false);
    // const classes = useStyles();
    let linkIcon={
        cursor:'pointer',
        color:'grey',
        fontSize:25, //16
        marginLeft:12,
        verticalAlign:'center',
        position:'relative',
        top:-4,
        transform:'rotate(-50deg)'
        
    }

    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

      const copyToClipBoard = () => {
        // let text = ""+window.location.origin+window.location.pathname+"#id"+id;
        navigator.clipboard.writeText(props.link);
    }

    return (
        <div>
            <Tooltip title="Copy URL" aria-label="copy" arrow>
                <LinkIcon style={linkIcon}  onClick={()=>{
                    handleClick()
                    copyToClipBoard()
                    }}/>
                {/* <i className={`fas fa-link`} 
                style={linkIcon}
                onClick={()=>{
                    handleClick()
                    copyToClipBoard()
                    }}> </i> */}
            </Tooltip>
            


            {/* <div style={{
                width:'50px',maxWidth:50,
                }}> */}
            <Snackbar
                style={{
                height:20,
                width:'50px',
                fontSize:2,
                padding:0,
                margin:0,
                // maxWidth:50,
                }}
                // classes={{
                //     root:classes.snackbar
                // }}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}

                open={open}
                onClose={handleClose}
                autoHideDuration={1000}
                message="Copied"
            />
            {/* </div> */}
        </div>
    )
}

export default TooltipLinkShare
