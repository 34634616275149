import { clearCookie } from './cookiesInfo';
import firebase from './firebase-config'

export const socialMediaAuth = (provider) => {
    return firebase
        .auth()
        .signInWithPopup(provider)
        .then((res) => {
            // console.log(res);
            return res; //return res.user;
        })
        .catch((err) => {
            return err;
            // console.log(err);
        })
}

export const getCurrentlySignedInUser = () => {
    return firebase
        .auth()
        .onAuthStateChanged((user) => {
            if (user) {

            } else {

            }
        })
}

export const signOutUser = () => {
    return firebase
        .auth()
        .signOut()
        .then(() => {
            console.log('logout successfull');
        })
        .catch((error) => {
            console.log('logout unsuccessfull');
        })
}
export const logoutUser = () => {
    firebase
        .auth()
        .signOut()
        .then(() => {
            console.log('logout successfull');
            clearCookie();
            // history.push('/');
        })
        .catch((error) => {
            console.log('logout unsuccessfull', error);
        })
}








