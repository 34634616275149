import { FETCH_LAW_FAILURE, FETCH_LAW_SUCCESS } from "./FetchLawListTypes"

import {SET_LAW_DATA, SET_LAW_LOAD, SET_LAW_OFFSET} from "./FetchLawListTypes"


const initialState = {
    data : [],
    error : '',
    offset : 0,
    loadMore : true,
    // language : 1
}

const fetchLawListReducer = (state = initialState, action) =>{

    switch(action.type){

        case FETCH_LAW_FAILURE:
            return {
                ...state,
                data : [],
                error : action.error
            }
        case FETCH_LAW_SUCCESS:
            return {
                ...state,
                data : action.payload,
                error : '',
                offset : action.offset,
                loadMore : action.loadMore,

            }
        case SET_LAW_DATA:
            return {
                ...state,
                data : action.payload,
                // error : action.error
            }
        case SET_LAW_LOAD:
            return {
                ...state,
                loadMore : action.payload,
                // error : action.error
            }
        case SET_LAW_OFFSET:
            return {
                ...state,
                offset : action.payload,
                // error : action.error
            }
        
        default :
            return state;

    }
}

export default fetchLawListReducer