import { FETCH_JUDGEMENT_FAILURE, FETCH_JUDGEMENT_SUCCESS} from "./FetchJudgementListTypes"

import {SET_JUDGEMENT_DATA, SET_JUDGEMENT_LOAD, SET_JUDGEMENT_OFFSET, SET_JUDGEMENT_FROM, SET_JUDGEMENT_TO} from "./FetchJudgementListTypes"

import {getToken} from '../../../config/cookiesInfo'

import {HOST} from '../../../config/config'
import axios from 'axios'

export const setJudgementData = (data) => {
    return {
        type : SET_JUDGEMENT_DATA,
        payload : data,
    }
}
export const setJudgementOffset = (offset) => {
    return {
        type : SET_JUDGEMENT_OFFSET,
        payload : offset,
    }
}
export const setJudgementLoad = (load) => {
    return {
        type : SET_JUDGEMENT_LOAD,
        payload : load,
    }
}

export const setJudgementFrom = (from) => {
    return {
        type : SET_JUDGEMENT_FROM,
        payload : from,
    }
}

export const setJudgementTo = (to) => {
    return {
        type : SET_JUDGEMENT_TO,
        payload : to,
    }
}




export const fetchJudgementListSuccess = (data,offsetVal,Load) => {
    return {
        type : FETCH_JUDGEMENT_SUCCESS,
        payload : data,
        offset : offsetVal,
        loadMore : Load
    }
}
export const fetchJudgementListFailure = (error) => {
    return {
        type : FETCH_JUDGEMENT_FAILURE,
        payload : error
    }
}





export const fetchJudgementList = (fromDate,toDate,offset) => {
    return  async (dispatch)=>{
        return new Promise((resolve, reject)=>{
            axios.get(`${HOST}judgement/?date__gte=${fromDate}&date__lte=${toDate}&limit=20&offset=${offset}`,
                    {
                        headers:{
                            "Authorization" : `token ${getToken()}`
                        }
                    })
                    ///judgement/?date__lte=&date__gte=&date=1947-01-02
            .then((res)=>{
                resolve(res);
        
            }).catch((err)=>{
                reject(err)
            });
    
        })
    }
}



export const fetchRecentJudgements = () => {
    return  async (dispatch)=>{
        return new Promise((resolve, reject)=>{
            let date = (new Date().toISOString()+"").substring(0,10) 

            axios.get(`${HOST}judgement/?date__lte=${date}`,
                    {
                        headers:{
                            "Authorization" : `token ${getToken()}`
                        }
                    })
            .then((res)=>{
                // console.log(res.data.count);
                let total = res.data.count;
                let offsetValue = (Math.floor(total/20)*20)-20;

                axios.get(`${HOST}judgement/?limit=40&offset=${offsetValue}`,
                {
                    headers:{
                        "Authorization" : `token ${getToken()}`
                    }
                })
                .then((res)=>{
                    resolve(res);

                }).catch((err)=>{
                    reject(err)
                });
        
            }).catch((err)=>{
                reject(err)
            });
    
        })
    }
}


export const fetchSpecificJudgement = (id) => {
    
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}judgement/${id}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
            
        }).catch((err)=>{
            reject(err)
        });

    })
   
}



