import React,{useEffect} from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
// import LeftSidebar from '../../components/SidebarSection/LeftSidebar';

//fetchScheduleList

import {fetchScheduleList} from '../../Redux/APIs/FetchScheduleList/FetchScheduleListActions'
import {setMenuItems} from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { firebaseAnalytics } from '../../config/firebase-config'

const useStyles = makeStyles((theme) => ({
    heading:{
        textAlign:'center',
        paddingTop:30,
        fontWeight:600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]:{
            paddingTop:15,
            fontSize:22,
            marginBottom:-7,
        },
        [theme.breakpoints.up('sm')]:{
            paddingTop:25,
            fontSize:24,
        },
        [theme.breakpoints.up('md')]:{
            paddingTop:31,
        },
        [theme.breakpoints.up('lg')]:{
            paddingTop:35,
            fontSize:26,
        }
    },
    divider:{
        width:'90%',
        align:'center',
        backgroundColor:'black'
    },
    content:{ 
        
        // backgroundColor:'red',
        width:'80%',
        margin:'auto',

        [theme.breakpoints.up('xs')]:{
            marginTop:0,
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:-7,
        },
        [theme.breakpoints.up('md')]:{
            marginTop:0,
        }
       
    },
    container:{
        align:'center',
        
    },
    spinner:{
        margin: "auto",
        marginTop: "20%",
        // padding:'auto',
        color:'red',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer:{
        display:"flex"
    },
    listStyle:{
        // marginTop:20,
        [theme.breakpoints.up('xs')]:{
            fontSize:15,
            marginBottom:3,
        },
        [theme.breakpoints.up('sm')]:{
            fontSize:17.5,
            marginBottom:5,
        },
        [theme.breakpoints.up('lg')]:{
            fontSize:18,
            marginBottom:6,
        },
    }
   
}));




function Schedules({language_type,scheduleList,fetchScheduleList,setMenuItems,...props}) {

    const classes = useStyles();
    // const [data,setData] = useState([]);
    const history = useHistory();


    useEffect(()=>{
        // window.scrollTo(0,0);
        setMenuItems([],1);
        // firebaseAnalytics.logEvent(`ScheduleListPage_visited`);
    },[]) //setMenuItems


    useEffect(()=>{
        // console.log('shailesh');

        fetchScheduleList(language_type);
       
    },[language_type,fetchScheduleList]) //fetchScheduleList




    return (
        <>
        {/* <LeftSidebar/> */}
            <MainContentSection>
                <div className={classes.container}>
                    <h2 className={classes.heading}>Schedules</h2>
                    <hr className={classes.divider}/>
                    <div className={classes.content}>
                        {
                            // console.log('sss')
                            scheduleList.length===0?
                            <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner}/></div>
                            :
                            scheduleList.map((info,index)=>
                            {
                                    // console.log('schedules_info',info)
                                    return (

                                            <ListItem style={{cursor:'pointer'}} key={index} onClick={()=>{
                                                history.push(`/constitution/schedules/${info.id}`,info)
                                                }}>
                                                <h5 className={classes.listStyle}><b>{info.title}</b></h5>
                                            </ListItem>

                                        // <h5 className={classes.listStyle} style={{cursor:'pointer'}} key={index} 
                                        // onClick={()=>history.push(`/constitution/schedules/${info.id}`)}>
                                            
                                        //         <b>{info.title}</b>
                                            
                                        // </h5>
                                    )
                                })
                          
                            
                        }
                        
                    </div>
                </div>
            </MainContentSection>
        </>
    )
}

const mapStateToProps = (state) =>{

    let {toggleLanguage:{currentLanguageType},fetchScheduleList:{data}} = state

    return {
        language_type : currentLanguageType,
        scheduleList : data
    }

  }


const mapDispatchToProps = (dispatch) =>{
    return {
        fetchScheduleList : (languageType) => dispatch(fetchScheduleList(languageType)),
        setMenuItems : (val,i) => dispatch(setMenuItems(val,i)),
    }
  }


  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Schedules)

