import { DISPLAY_LEFT_SIDEBAR } from "./DisplayLeftSidebarTypes"

const initialState = {
    displayLeftSidebar : true,
}

const displayLeftSidebarReducer = (state = initialState, action) =>{
    switch(action.type){
        case DISPLAY_LEFT_SIDEBAR:
            return {
                ...state,
                displayLeftSidebar : action.value,
            }
        default :
            return state;

    }
}

export default displayLeftSidebarReducer