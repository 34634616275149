import {HOST} from '../../../config/config'
import axios from 'axios'

export const signUpUser = (data) => {
    return new Promise((resolve, reject)=>{

        axios.post(`${HOST}signup/`,data)
        .then((res)=>{
            // console.log(res.data.results)
            resolve(res);
            

        }).catch((err)=>{
            reject(err)
        });

    })
   
}