import firebase from 'firebase'


// const firebaseConfig = {
//     apiKey: "AIzaSyCoR-KZH5z5rXMLQrdJeL3b7ob9HJ5SAXQ",
//     authDomain: "constitution-of-india-14203.firebaseapp.com",
//     databaseURL: "https://constitution-of-india-14203.firebaseio.com",
//     projectId: "constitution-of-india-14203",
//     storageBucket: "constitution-of-india-14203.appspot.com",
//     messagingSenderId: "824179449954",
//     appId: "1:824179449954:web:a41a01793f429beb29713b",
//     measurementId: "G-H85N0XDV8Q"
//   };



const firebaseConfig = {
    apiKey: "AIzaSyChvL-VREdBqgT6MBtFNqWW9Nb8JJWbmVY",
    authDomain: "coi-webapp.firebaseapp.com",
    projectId: "coi-webapp",
    storageBucket: "coi-webapp.appspot.com",
    messagingSenderId: "831697195265",
    appId: "1:831697195265:web:34e89b09a98b031a84cb00",
    measurementId: "G-J4JFWCMYBG"
  };  

firebase.initializeApp(firebaseConfig);
// firebase.analytics();

export const firebaseAnalytics = firebase.analytics();
// firebaseAnalytics.logEvent('homepage_visited');
export default firebase