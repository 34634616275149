import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { connect } from 'react-redux'
import FullScreenContent from '../../components/MainContent/FullScreenContent';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router-dom'

import useState from 'react-usestateref'
import { fetchPreambles } from '../../Redux/APIs/FetchDetails/FetchPreambles'
import { fetchSchedules } from '../../Redux/APIs/FetchDetails/FetchSchedules'
import { fetchSections } from '../../Redux/APIs/FetchDetails/FetchSections'
import { fetchChapters } from '../../Redux/APIs/FetchDetails/FetchChapters'
import { fetchParts } from '../../Redux/APIs/FetchDetails/FetchParts'
import { logoutUser } from '../../config/firebaseAuthentication';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        // paddingTop:30,
        fontWeight: 600,
        marginLeft: 20,
        marginRight: 20,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 25,
            fontSize: 18,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 35,
            fontSize: 20,
            paddingBottom: 10,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 36,
            paddingBottom: 10,
            fontSize: 22,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 24,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {
        width: '80%',
        margin: 'auto',
        fontSize: 17,
        [theme.breakpoints.up('xs')]: {
            marginTop: -30,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }

    },
    container: {
        align: 'center',

    },
    textContent: {
        marginTop: 30,
    },
    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));




function IndividualLaw(props) {
    const classes = useStyles();
    const history = useHistory();

    const [preamble, setPreamble, refPreamble] = useState([]);
    const [schedule, setSchedule, refSchedule] = useState([]);
    const [section, setSection, refSection] = useState([]);

    const [part, setPart, refPart] = useState([]);
    const [chapter, setChapter, refChapter] = useState([]);



    let status = props.location.state ? true : false;

    let title = props.location.state ? props.location.state.title : '';
    let id = props.location.state ? props.location.state.id : '';
    let has_part = props.location.state ? props.location.state.has_part : '';
    let has_chapter = props.location.state ? props.location.state.has_chapter : '';
    let has_schedule = props.location.state ? props.location.state.has_schedule : '';
    let has_preamble = props.location.state ? props.location.state.has_preamble : '';


    // title : info.title,
    // id : info.id,
    // // srno:info.id,
    // description : info.description,
    // link : info.link,
    // year : info.year,
    // has_part : info.has_part,
    // has_chapter : info.has_chapter,
    // has_schedule : info.has_schedule,
    // has_preamble : info.has_preamble,

    useEffect(() => {

        if (status) {
            fetchPreambles(id)
                .then((res) => {

                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    setPreamble(arr);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        logoutUser()
                        history.push("/")
                    }
                });
        }

    }, [])


    useEffect(() => {
        if (status) {
            fetchSchedules(id)
                .then((res) => {

                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    setSchedule(arr);
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response.status === 401) {
                        logoutUser()
                        history.push("/")
                    }
                });
        }
    }, [])




    useEffect(() => {
        if (status) {
            if (has_part === false && has_chapter === false) {
                fetchSections(id, '', '')
                    .then((res) => {
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        setSection(arr);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 401) {
                            logoutUser()
                            history.push("/")
                        }
                    });
            }
        }

    }, [])



    useEffect(() => {
        if (status) {
            if (has_part === false && has_chapter === true) {
                fetchChapters(id, '')
                    .then((res) => {

                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        setChapter(arr);
                    })
                    .catch((err) => {
                        console.log(err);
                        logoutUser()
                        history.push("/")
                    });
            }
        }

    }, [])


    useEffect(() => {
        if (status) {
            if (has_part === true) {
                fetchParts(id)
                    .then((res) => {

                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        setPart(arr);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 401) {
                            logoutUser()
                            history.push("/")
                        }
                    });
            }
        }

    }, [])

    return (
        <>
            {
                !status ?//props.location.state
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3>
                        </div>
                    </FullScreenContent> :
                    <FullScreenContent>
                        <div className={classes.container}>
                            <h2 className={classes.heading}>{title}</h2>
                            <hr className={classes.divider} />
                            <div className={classes.content}>

                                <List>
                                    {
                                        refPreamble.current.map((info, index) => {
                                            // console.log('individuallawpreamble_info', info)
                                            return (
                                                <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                    history.push(`/laws/individualLaw/preamble/${info.id}`)
                                                }}>
                                                    <h5 className={classes.itemStyle}><b>{info.title}</b></h5>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>


                                <List>
                                    {
                                        refSchedule.current.map((info, index) => {
                                            // console.log('individuallawschedule_info', info)
                                            return (
                                                <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                    history.push(`/laws/individualLaw/schedule/${info.id}`, info.id)
                                                }}>
                                                    <h5 className={classes.itemStyle}>
                                                        <b>
                                                            {info.title}
                                                        </b>
                                                    </h5>

                                                    {/* Schedule {index+1} - ({info.title}) */}

                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>

                                <List>
                                    {
                                        refSection.current.map((info, index) => {
                                            // console.log('individuallawsection_info', info)
                                            return (
                                                <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                    history.push(`/laws/individualLaw/section/${info.id}`, info.id)
                                                }}>
                                                    <h5 className={classes.itemStyle}>
                                                        <b>Section  {info.section}</b>
                                                        ({info.title})
                                                    </h5>
                                                    {/* {""+info.title.length>0?`(${info.title})`:``} */}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>


                                <List>
                                    {
                                        refChapter.current.map((info, index) => {
                                            // console.log('individuallawsectionList_info', info)

                                            return (
                                                <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                    history.push('/laws/individualLaw/sectionList',
                                                        {
                                                            // content:info.content,
                                                            // title:info.title,
                                                            chapterId: info.id,
                                                            partId: '',
                                                            lawId: info.law
                                                        })
                                                }}>
                                                    <h5 className={classes.itemStyle}>
                                                        <b>
                                                            {info.title}
                                                            {
                                                                info.description && info.description.length > 0 ?
                                                                    `(${info.description})`
                                                                    : ``
                                                            }
                                                        </b>
                                                    </h5>
                                                    {/* <h5><b>{info.title} ({info.description})</b></h5> */}
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>



                                <List>
                                    {
                                        refPart.current.map((info, index) => {
                                            // console.log('individuallawchapterList_info', info)
                                            return (
                                                <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                    history.push('/laws/individualLaw/chapterList',
                                                        {
                                                            // content:info.content,
                                                            // title:info.title,
                                                            partId: info.id,
                                                            // chapterId : null,
                                                            lawId: info.law
                                                        })
                                                }}>
                                                    <h5 className={classes.itemStyle}>
                                                        <b>
                                                            {info.title}
                                                            ({info.description})
                                                        </b>
                                                    </h5>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>

                            </div>
                        </div>
                    </FullScreenContent>
            }
        </>

    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
    }
}

export default connect(
    null,
    mapDispatchToProps
)(IndividualLaw)



/* {props.location.state.content.split('\n').map((item,index)=>{
                     return (<p className={classes.textContent} key={index}>
                         {item}
                     </p>)

                 })} */
