import React,{useState} from 'react'
import { makeStyles, fade } from '@material-ui/core/styles';
import './TopSearchBar.css'
import { useHistory } from 'react-router-dom';
import {
    Box,
    Icon, 
    Divider,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    // dropdownMenuSearch :{
    //     width: 120,
    //     height: 30,
    //     outline: 'none',
    //     border: 0,
    //     paddingLeft: 5,
    //     // color:'red',
    //     // fontSize:10,
    //     [theme.breakpoints.up('xs')]:{
    //         fontSize:13,
    //     },
    //     [theme.breakpoints.up('sm')]:{
    //         fontSize:15,
    //     },

    // },
    // inputSearch:{
    //     width: 280,
    //     height: 30,
    //     outline: 'none',
    //     border: 0,
    //     borderLeft: "2px Solid black",
    //     paddingLeft: 10,
    //     [theme.breakpoints.up('xs')]:{
    //         fontSize:13,
    //     },
    //     [theme.breakpoints.up('sm')]:{
    //         fontSize:15,
    //     },
    // },
    // searchIcon:{
    //     fontSize: 20,
    //     marginLeft: 10,
    //     marginTop: 4,
    //     color: 'white',
    // },
    searchBar:{
        backgroundColor:theme.palette.primary.main,
        width:'100%'
    },
    divider:{
        backgroundColor:'white',
        height:1
    },
    searchBarContainer:{
        width:'100%',
        position:'fixed',
        zIndex: theme.zIndex.drawer + 1,
    },







    inputSearch:{
        backgroundColor: fade(theme.palette.common.white, 0.20),
        width: 230,
        height: 25,
        outline: 'none',
        border: 0,
        color:'white',
        borderLeft: "2px Solid grey",
        // borderRadius: theme.shape.borderRadius,
        paddingLeft: 15,
        fontSize:13,
     
      },

      searchIcon:{
        fontSize: 17,
        marginLeft: 6,
        marginTop: 4,
        color: 'white',
      },
      dropdownMenuSearch :{
        backgroundColor: fade(theme.palette.common.white, 0.2),
        width: 110,
        height: 25,
        outline: 'none',
        border: 0,
        paddingLeft: 5,
        color:'white',

        fontSize:11,
       
      },
      option:{
        color:'black'
      },


}));







function TopSearchBar() {
    const classes = useStyles();
    const history = useHistory();
    const [catagory,setCatagory] = useState('articles_index');
    const [searchText,setSearchText] = useState('');

    return (
        <div className={`${classes.searchBarContainer} searchBarContainerStyle`} >
            <Divider className={classes.divider}/>
            <Box display="flex" justifyContent="center"  p={1} className={classes.searchBar}>
                    <select className={classes.dropdownMenuSearch} 
                    value={catagory} 
                    onChange={(e)=>setCatagory(e.target.value)}
                    style={{
                        marginLeft:30,
                    }}
                    
                    >

                        {/* <option value='All' className={classes.option}>All</option>
                        <option value='Constitution' className={classes.option}>Constitution</option>
                        <option value='Articles' className={classes.option}>Articles</option>
                        <option value='Amendments' className={classes.option}>Amendments</option> */}

                        <option value='articles_index' className={`${classes.option} `}>Article</option>
                        <option value='amendments_index' className={`${classes.option} `}>Amendment</option>
                        <option value='schedules_index' className={`${classes.option} `}>Schedule</option>
                        <option value='laws_index' className={`${classes.option} `}>Law</option>
                        <option value='debates_index' className={`${classes.option} `}>Debate</option>
                        <option value='judgements_index' className={`${classes.option} `}>Judgement</option>


                    </select>

                    <input 
                    onChange={(e)=>setSearchText(e.target.value)} 
                    value={searchText} 
                    type='text' 
                    className={classes.inputSearch} 
                    placeholder='Search...'
                    onKeyUp={(e)=>{
                        if(e.key==='Enter')
                            history.push(`/searchResult/${searchText}/${catagory}`,{text:searchText,catagory:catagory})
                    }}
                    />
                    
                    <Icon
                    style={{cursor:'pointer'}}
                    className={`fa fa-search ${classes.searchIcon}`} 
                    onClick={()=>history.push(`/searchResult/${searchText}/${catagory}`,{text:searchText,catagory:catagory})} />
            </Box>
        </div>
    )
}

export default TopSearchBar
