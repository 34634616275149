import { OPEN_RIGHT_SIDEBAR } from "./OpenRightSidebarTypes"

const initialState = {
    isOpen : false
}

const openRightSidebarReducer = (state = initialState, action) =>{
    switch(action.type){
        case OPEN_RIGHT_SIDEBAR:
            return {
                ...state,
                isOpen : action.value,
            }
        default :
            return state;

    }
}

export default openRightSidebarReducer