import { FETCH_CONSTITUTION_FAILURE,FETCH_CONSTITUTION_SUCCESS } from "./ConstitutionMenuItemsTypes"

const initialState = {
    data : [],
    error : ''
}

const constitutionMenuItemsReducer = (state = initialState, action) =>{

    switch(action.type){
        case FETCH_CONSTITUTION_FAILURE:
            return {
                data : [],
                error : action.error
            }
        case FETCH_CONSTITUTION_SUCCESS:
            return {
                data : action.payload,
                error : ''
            }
        default :
            return state;

    }
}

export default constitutionMenuItemsReducer