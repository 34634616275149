import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TabContext, TabList } from '@material-ui/lab';
import { Paper, Grid, Tab, Select, Container, Typography, CircularProgress, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { AdminDashboardData } from '../Admin_APIs'
import FullScreenContent from '../../MainContent/FullScreenContent'
import DataList from './DataList';
// import DropDownMenu from 'material-ui/DropDownMenu';
// import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
        '& label.Mui-focused': {
            color: theme.palette.primary.main,//color: 'red',

        },
        '& .MuiOutlinedInput-root': {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 45,
            },
            '& fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,


            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
                //borderColor: 'red',
            },
            '&.Mui-focused fieldset': {
                border: '3px solid red',
                borderColor: theme.palette.primary.main,
            },
            margin: theme.spacing(1),
            minWidth: 120,
        },
    },
    paper: {
        padding: theme.spacing(1),

    },
    heading: {
        textAlign: 'center',
        fontWeight: 600,
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',

        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    papertitle: {
        padding: theme.spacing(1),
        fontSize: 22,
        textAlign: 'center'
    },
    tableheadcell: {
        fontWeight: 'bold',
        fontSize: 18,
        textAlign: 'center'
    },
    tablebodycell: {
        padding: theme.spacing(3),
        fontSize: 15,
        textAlign: 'center',
    },
    Select: {
        padding: theme.spacing(2),
        fontSize: 16,
        fontWeight: 'bold',
    },
    btn: {
        textAlign: 'center',
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    TabContext: {
        border: 'none'
    }

}));



const AdminDashboardKnowledgeGraphList = (props) => {
    // console.log('AdminDashboardKnowledgeGraphList_props',props)
    const classes = useStyles();
    const [results, setResults] = React.useState([])
    const [loaded, setLoaded] = React.useState(false)
    const [offset, setOffset] = React.useState('')
    // console.log('results===>',results)
    // console.log('loaded===>',loaded)
    ///////////////////////////////////////////////////////////////////////////////////////
    const [value, setValue] = React.useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
        setOffset('')
        setLoaded(false)

    }

    ///////////////////////////////////////////////////////////////////////////////////////
    const [filterdata, setFilterData] = React.useState('')
    const selectHandler = (event) => {
        setFilterData(event.target.value)
        setOffset('')
        setLoaded(false)
    }

    const [count, setCount] = React.useState()

    ///////////////////////////////////////////////////////////////////////////////////////
    const filter = {
        filter_type: filterdata,
        object_type: value,
        offset_type: offset,
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    React.useEffect(() => {
        AdminDashboardData(filter)
            .then((res) => {
                setResults(res.data.results)
                setCount(res.data.count)
                setLoaded(true)
            })
            .catch((err) => {
                // console.log(err);
            })
    }, [value, filterdata, offset])

    ///////////////////////////////////////////////////////////////////////////////////////


    function Fillters() {
        return (
            <Grid item xs={4}>

                <FormControl variant="outlined" className={classes.root}>
                    <InputLabel className={classes.formControl} >Status</InputLabel>
                    <Select
                        value={filterdata}
                        onChange={selectHandler}
                        label="Status"
                    >
                        <MenuItem value=' '>All</MenuItem>
                        <MenuItem value='is_pending=true'>Pending</MenuItem>
                        <MenuItem value='is_published=true'>Published</MenuItem>
                        <MenuItem value='is_rejected=true'>Rejected</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        );
    }


    ///////////////////////////////////////////////////////////////////////////////////////

    return (
        <FullScreenContent>
            <h2 className={classes.heading}>Admin DashBoard</h2>
            <hr className={classes.divider} />
            <Container>
                <Fillters />
                <Paper square>
                        <Tabs onChange={handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                            value={value}
                            className='mb-2'
                        >
                            <Tab label="all" value="" />
                            <Tab label="articles" value="article" />
                            <Tab label="amendments" value="amendment" />
                            <Tab label="schedules" value="schedule" />
                            <Tab label="law schedules" value="law_schedule" />
                            <Tab label="Sections" value="section" />
                        </Tabs>
                </Paper>
                {loaded ?
                    <>
                        {results.length !== 0 ?
                            <DataList
                                results={results}
                                value={value}
                                setOffset={setOffset}
                                count={count}
                            />
                            :
                            <Paper className={classes.papertitle}>No data found..</Paper>
                        }
                    </>
                    :
                    <div className='text-center mt-5'>
                        <CircularProgress />
                    </div>
                }
            </Container>
        </FullScreenContent >

    )
}
export default AdminDashboardKnowledgeGraphList
