import axios from "axios";
import { HOST } from "../../config/config";
import { getToken } from "../../config/cookiesInfo";

export const deleteUserAccount = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${HOST}delete_user/`,data,
            {
                headers: {
                    "Authorization": `token ${getToken()}`
                },  
            })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });

    })
}

export const fetchAccountDeletionReason = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${HOST}get_account_deletion_reason/`,
            {
                headers: {
                    "Authorization": `token ${getToken()}`
                }
            })
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });

    })

}