import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';

import { CircularProgress, Button } from '@material-ui/core';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchArticleList } from '../../Redux/APIs/FetchArticleList/FetchArticleListActions'
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { firebaseAnalytics } from '../../config/firebase-config'

// import { Link } from 'react-scroll';
// import { HashLink as Link } from 'react-router-hash-link';
import TooltipLinkShare from '../../components/Tooltip/TooltipLinkShare';
import { useHistory } from 'react-router-dom'

import KnowledgeGraphButton from '../../components/KnowledgeGraph/KnowledgeGraphButton'



const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: -20,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'red',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    articleTitle: {
        marginTop: 20,

        [theme.breakpoints.up('xs')]: {
            fontSize: 18,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 19,
            marginBottom: 5,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 21,
            marginBottom: 6,
        },
    },
    articleDescription: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            // marginBottom:5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            // marginBottom:6,
        },

    },

    articleContent: {
        marginBottom: 20,
    },
    linkIcon: {
        cursor: 'pointer',
        color: 'grey',
        fontSize: 16,
        marginLeft: 12,
        verticalAlign: 'center',
        position: 'relative',
        top: -4,
    }

}));


function Articles({ language_type, fetchArticleList, setMenuItems, ...props }) {
    // console.log('Articles_props', props)
    const classes = useStyles();
    const history = useHistory();
    const [data, setData, refData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [load, setLoad] = useState(true);
    const [spinner, setSpinner, refSpinner] = useState(false);


    // let partId = props.location.state? props.location.state.partId: 0;
    // let chapterId = props.location.state? props.location.state.chapterId: 0;

    let partId = props.match.params.partId
    let chapterId = props.match.params.chapterId





    useEffect(() => {
        window.scrollTo(0, 0);
        setMenuItems([], 1);
        // firebaseAnalytics.logEvent(`ArticlesPage_visited`);
    }, [props, setMenuItems])
    //setMenuItems


    useEffect(() => {
        setData([]);
        setOffset(0)
    }, [language_type, partId, chapterId, setData,])
    //setData

    useEffect(() => {

        setSpinner(true)
        fetchArticleList(offset, language_type, partId, chapterId)
            .then((res) => {
                setSpinner(false)
                let arr = res.data.results;
                arr.sort((a, b) => {
                    return a.id - b.id;
                })
                setData([...refData.current, ...arr])

                if (res.data.next === null)
                    setLoad(false);


            }).catch((err) => {
                console.log(err);
            });

    }, [offset, language_type, partId, chapterId, fetchArticleList, setData, refData])
    //  fetchArticleList,setData,refData



    window.requestAnimationFrame(() => {
        let id = "" + window.location.hash.substring(1);
        if (id.length > 0) {
            if (document.getElementById(id)) {
                window.scrollTo({ top: document.getElementById(id).getBoundingClientRect().top + window.pageYOffset + (-130), behavior: 'smooth' })

            }

        }
    });


    const copyToClipBoard = (id) => {
        let text = "" + window.location.origin + window.location.pathname + "#id" + id;
        navigator.clipboard.writeText(text);
    }


    return (
        <>
            {
                // !props.location.state?
                // <MainContentSection>
                //     <div className={classes.container}>
                //         <h3 className={classes.heading} style={{paddingTop:50}}>No data found</h3>
                //     </div>
                // </MainContentSection>:
                <MainContentSection>
                    <div className={classes.container}>
                        <h2 className={classes.heading}>Articles</h2>
                        <hr className={classes.divider} />
                        <div className={classes.content}>
                            {/* <>
                        <ul>
                        {
                            data.map((info,index)=>
                            {
                                return <li key={index}>
                                    <Link to={`#id${info.id}`} 
                                        scroll = {(el) =>{ 
                                            window.scrollTo({top: el.getBoundingClientRect().top + window.pageYOffset + (-130), behavior: 'smooth'})
                                        }}>{info.title}</Link>

                                </li>
                            })

                        }

                        </ul>
                    </>  */}

                            {
                                data.length === 0 ?
                                    refSpinner.current === true ?
                                        <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                        <h3 className={classes.heading} style={{ paddingTop: 50 }}>No data found</h3> :
                                    <InfiniteScroll
                                        dataLength={data.length}
                                        next={() => setOffset(offset + 20)}
                                        hasMore={load}
                                        loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                                        style={{ overflow: 'hidden' }}>

                                        {
                                            data.map((info, index) => {
                                                // console.log('title',info.title,'id:',info.article_id)
                                                // console.log('Article_info', info)
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className={classes.articleTitle} id={`id${info.id}`} style={{ display: 'flex' }}>
                                                            <b>{info.title}</b>
                                                            <TooltipLinkShare link={"" + window.location.origin + window.location.pathname + "#id" + info.id} />

                                                            <KnowledgeGraphButton data={info} pathname={'articles'} previous={props.location.pathname} id={info.id} />
                                                        </div>

                                                        <h5 className={classes.articleDescription}><b>{info.description}</b></h5>
                                                        {
                                                            info.content.split('\n').map((item, index) => {
                                                                return (<p className={classes.articleContent} key={index}>
                                                                    {item}
                                                                </p>)

                                                            })
                                                        }
                                                        <br />
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                            }

                        </div>
                    </div>
                </MainContentSection>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        language_type: state.toggleLanguage.currentLanguageType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchArticleList: (offset, languageType, partid, chapterid) => dispatch(fetchArticleList(offset, languageType, partid, chapterid)),
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
    }
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Articles)

