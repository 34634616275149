// module.exports={
//     HOST : "https://articlenumber21.com/coi/",
// };

export const HOST = "https://api.articlenumber21.com/coi/";
// export const HOST = "https://330a-2401-4900-1c21-2d85-fabe-9f7c-9bda-ccec.ngrok-free.app/coi/";
// export const HOST = "http://192.168.1.25:8080/coi/";

// if(process.env.NODE_ENV == 'production'){
//     module.exports={
//         HOST : "https://articlenumber21.com/coi/",
//     };
// }










