import { Button, Modal } from "react-bootstrap";

export default function ConfirmationModal(props) {
    const { title, description } = props
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={props.onDelete}>Delete</Button>
                <Button variant="secondary" onClick={props.onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>

    );
}