import { FETCH_DEBATE_FAILURE, FETCH_DEBATE_SUCCESS } from "./FetchDebateListTypes"

import { SET_DEBATE_DATA, SET_DEBATE_LOAD, SET_DEBATE_OFFSET, SET_DEBATE_LANGUAGE, SET_DEBATE_YEAR } from "./FetchDebateListTypes"

import { getToken } from '../../../config/cookiesInfo'

import { HOST } from '../../../config/config'
import axios from 'axios'
import { logoutUser, signOutUser } from "../../../config/firebaseAuthentication"

export const setDebateData = (data) => {
    return {
        type: SET_DEBATE_DATA,
        payload: data,
    }
}
export const setDebateOffset = (offset) => {
    return {
        type: SET_DEBATE_OFFSET,
        payload: offset,
    }
}
export const setDebateLoad = (load) => {
    return {
        type: SET_DEBATE_LOAD,
        payload: load,
    }
}
export const setDebateLanguage = (lang) => {
    return {
        type: SET_DEBATE_LANGUAGE,
        payload: lang,
    }
}
export const setDebateYear = (year) => {
    return {
        type: SET_DEBATE_YEAR,
        payload: year,
    }
}



export const fetchDebateListSuccess = (data, offsetVal, Load) => {
    return {
        type: FETCH_DEBATE_SUCCESS,
        payload: data,
        offset: offsetVal,
        loadMore: Load
    }
}
export const fetchDebateListFailure = (error) => {
    return {
        type: FETCH_DEBATE_FAILURE,
        payload: error
    }
}





export const fetchDebateList = (offset, year, history) => {
    return async (dispatch) => {
        return new Promise((resolve, reject) => {

            axios.get(`${HOST}debate/?limit=20&offset=${offset}&year__name__icontains=${year}`,
                {
                    headers: {
                        "Authorization": `token ${getToken()}`
                    }
                })

                .then((res) => {

                    resolve(res);

                }).catch((err) => {
                    reject(err)
                });

        })
    }
}




export const fetchSpecificDebate = (id) => {

    return new Promise((resolve, reject) => {
        axios.get(`${HOST}debate/${id}/`,
            {
                headers: {
                    "Authorization": `token ${getToken()}`
                }
            })
            .then((res) => {
                resolve(res);

            }).catch((err) => {
                reject(err)
            });

    })

}






