export const FETCH_AMENDMENT_SUCCESS = 'fetchAmendmentSuccess';
export const FETCH_AMENDMENT_FAILURE = 'fetchAmendmentFailure';




export const SET_AMENDMENT_DATA = 'setAmendmentData';
export const SET_AMENDMENT_OFFSET = 'setAmendmentOffset';
export const SET_AMENDMENT_LOAD= 'setAmendmentLoad';
export const SET_AMENDMENT_LANGUAGE= 'setAmendmentLanguage';



