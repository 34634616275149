import {combineReducers} from 'redux'
import toggleLanguageReducer from '../ToggleLanguage/ToggleLanguageReducers'
import openRightSidebarReducer from '../OpenRightSidebar/OpenRightSidebarReducers'
import constitutionMenuItemReducer from '../ConstitutionMenuItems/ConstitutionMenuItemsReducer'
import resourceMenuItemReducer from '../ResourceMenuItems/ResourceMenuItemsReducer'
import setCurrentMenuItemReducer from '../SetCurrentMenuItems/SetCurrentMenuItemsReducer'
import displayLeftSidebarReducer from '../DisplayLeftSidebar/DisplayLeftSidebarReducer'



import fetchScheduleListReducer from '../APIs/FetchScheduleList/FetchScheduleListReducer'
import fetchAmendmentListReducer from '../APIs/FetchAmendmentList/FetchAmendmentListReducer'
import fetchArticleListReducer from '../APIs/FetchArticleList/FetchArticleListReducer'
import fetchDebateListReducer from '../APIs/FetchDebatesList/FetchDebateListReducer'
import fetchJudgementListReducer from '../APIs/FetchJudgementList/FetchJudgementListReducer'

import fetchLawListReducer from '../APIs/FetchLawList/FetchLawListReducer'
// import fetchKnowledgeGraphReducer from '../APIs/KnowledgeGraph/KnowledgeGraphReducer'

const rootReducer = combineReducers({
    toggleLanguage : toggleLanguageReducer,
    openRightSidebar  : openRightSidebarReducer,
    constitutionMenu : constitutionMenuItemReducer,
    resourceMenu : resourceMenuItemReducer,
    setMenuItem : setCurrentMenuItemReducer,
    displayLeftSidebar :displayLeftSidebarReducer,



    //API
    fetchScheduleList : fetchScheduleListReducer,
    fetchAmendmentList : fetchAmendmentListReducer,
    fetchArticleList : fetchArticleListReducer,
    fetchDebateList : fetchDebateListReducer,
    fetchJudgementList : fetchJudgementListReducer,
    fetchLawList : fetchLawListReducer,

    //knowledgeGraph API
    // fetchKnowledgeGraph:fetchKnowledgeGraphReducer
})

export default rootReducer