import React from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const TextEditor = (props) => {
  // console.log('TextEditor_props',editorDataState)
  // console.log('TextEditor_props',props)

  const contentDataState = ContentState.createFromBlockArray(convertFromHTML(`${props.value}`));

  const editorDataState = EditorState.createWithContent(contentDataState);

  const [editorState, setEditorState] = React.useState(editorDataState);

  const onEditorStateChange = (editorStateData) => {
    setEditorState(editorStateData);
    let data = draftToHtml(convertToRaw(editorStateData.getCurrentContent()));
    props.onChange(data);
    // console.log('TextEditor_data==>',data)

  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  )
}

export default TextEditor