import React, { useState } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box, Divider, Icon
  // Paper
} from '@material-ui/core';

import logo from '../../images/logo.jpg'
import AccountCircle from "@material-ui/icons/AccountCircle";

import RightSideBar from '../SidebarSection/RightSideBar';


import { openRightSidebar } from '../../Redux/OpenRightSidebar/OpenRightSidebarActions';
import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions';
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'


import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'
import './TopMenubar.css'

import { getStaffStatus } from '../../config/cookiesInfo';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    // top:0,
    zIndex: theme.zIndex.drawer + 1,
    width: '100%'

  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      fontSize: 17,

    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,

    },
    [theme.breakpoints.up('md')]: {
      fontSize: 23,
    },

  },
  menuItemSpacing: {

    marginRight: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      color: 'white',
      fontSize: 15,
      marginRight: 40,
      fontWeight: 500,

    },
    [theme.breakpoints.up('md')]: {
      // color:'black',
      fontSize: 17,
      marginRight: 50,
      // fontWeight:'bold',  
    },
    [theme.breakpoints.up('lg')]: {
      // color:'yellow',
      marginRight: 40,
    }
  },
  profileIcon: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,

    },
    [theme.breakpoints.down('sm')]: {
      marginRight: -15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    }
  },
  logo: {
    height: 60,
    width: 60,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -10,
      height: 55,
      width: 55,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 10,
      marginBottom: 10,
    }

  },
  mobileMenu: {
    backgroundColor: theme.palette.primary.main,

  },
  mobileMenuItem: {
    // position:'sticky',
    zIndex: theme.zIndex.drawer + 1,
    fontSize: 15,
    fontWeight: 500,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 5,
    paddingBottom: 7,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      fontSize: 12,
      fontWeight: 700,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 13,
      fontWeight: 700,
    }
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
  },
  mobileDivider: {
    backgroundColor: 'white'
  },






  inputSearch: {
    backgroundColor: fade(theme.palette.common.white, 0.20),
    width: 230,
    height: 35,
    outline: 'none',
    border: 0,
    color: 'white',
    borderLeft: "2px Solid grey",
    // borderRadius: theme.shape.borderRadius,
    paddingLeft: 15,
    [theme.breakpoints.up('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
    },
  },

  searchIcon: {
    fontSize: 20,
    marginLeft: 10,
    marginTop: 6,
    color: 'white',
  },
  dropdownMenuSearch: {
    backgroundColor: fade(theme.palette.common.white, 0.2),
    width: 120,
    height: 35,
    outline: 'none',
    border: 0,
    paddingLeft: 5,
    color: 'white',
    // color:'red',
    // fontSize:10,
    [theme.breakpoints.up('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
  },
  option: {
    // backgroundColor:theme.palette.primary.main,
    color: 'black'
  },
  searchContainer: {
    marginRight: 40,
    // flexGrow: 1,

  }










}));

function TopMenubar(props) {
  const classes = useStyles();
  const history = useHistory();
  const [catagory, setCatagory] = useState('articles_index');
  const [searchText, setSearchText] = useState('');
  // const [isOpen,setIsOpen] = React.useState(false);

  // const btnRef = React.useRef(null);


  return (
    <div className={classes.root}>
      <AppBar color='primary' className={classes.appBar}>
        <Toolbar>
          <img onClick={() => history.push('/')} style={{ cursor: 'pointer' }} alt='not able to display' src={logo} className={classes.logo} />

          <Typography onClick={() => history.push('/')} style={{ cursor: 'pointer' }} variant="h6" className={classes.title}>
            Indian Constitution and Polity
          </Typography>

          <Box display="flex" justifyContent="center" p={1} className={`${classes.searchContainer} m_searchContainer`}>
            <select className={`${classes.dropdownMenuSearch} m_dropdownMenuSearch`}
              value={catagory}
              onChange={(e) => setCatagory(e.target.value)}>
              {/* <option value='All' className={`${classes.option} m_option`}>All</option>
                    <option value='Constitution' className={`${classes.option} m_option`}>Constitution</option> */}

              <option value='articles_index' className={`${classes.option} m_option`}>Article</option>
              <option value='amendments_index' className={`${classes.option} m_option`}>Amendment</option>
              <option value='schedules_index' className={`${classes.option} m_option`}>Schedule</option>
              <option value='laws_index' className={`${classes.option} m_option`}>Law</option>
              <option value='debates_index' className={`${classes.option} m_option`}>Debate</option>
              <option value='judgements_index' className={`${classes.option} m_option`}>Judgement</option>

            </select>
            <input
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              type='text'
              className={`${classes.inputSearch} m_inputSearch`}
              placeholder='Search...'
              onKeyUp={(e) => {
                if (e.key === 'Enter')
                  history.push(`/searchResult/${searchText}/${catagory}`, { text: searchText, catagory: catagory })
              }}
            />
            <Icon
              style={{ cursor: 'pointer' }}
              className={`fa fa-search ${classes.searchIcon} m_searchIcon`}
              onClick={() => history.push(`/searchResult/${searchText}/${catagory}`, { text: searchText, catagory: catagory })} />
          </Box>
          {
            getStaffStatus() ?
              <Typography
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push('/admin/dashboard');
                }}
                variant="h6" className={`${classes.menuItemSpacing} displayitem`}>
                AdminDashboard
              </Typography>
              : ''
          }

          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.setMenuItems(props.constitutionMenu, 1);
              props.displayLeftSidebar(true);
              // console.log(props.constitutionMenu);
              // localStorage.setItem('leftsidebar',true);
              history.push('/constitution/preamble');
            }}
            variant="h6" className={`${classes.menuItemSpacing} displayitem`}>
            Constitution
          </Typography>

          <Typography
            style={{ cursor: 'pointer' }}
            onClick={() => {
              props.setMenuItems(props.resourceMenu, 2);
              props.displayLeftSidebar(true);
              // console.log(props.resourceMenu); 

              history.push('/resource');
              // history.push('/resource/constituentAssemblyDebates');
            }}
            variant="h6" className={`${classes.menuItemSpacing} displayitem`}>
            Resources
          </Typography>

          <Typography style={{ cursor: 'pointer' }}
            onClick={() => {
              // props.setMenuItems([],4);
              // props.displayLeftSidebar(false);

              history.push('/law');
              // history.push('/laws');
            }}
            variant="h6" className={`${classes.menuItemSpacing} displayitem`}>
            Laws
          </Typography>


          <IconButton
            // // aria-label="account of current user"
            // // aria-controls="menu-appbar"
            // aria-haspopup="true"
            style={{ outline: "none" }}
            color="inherit"
            onClick={() => {
              props.openRightSidebar(true);

              // btnRef.current.fnCallfromParent()
            }}
          >
            <AccountCircle className={classes.profileIcon} />
          </IconButton>
        </Toolbar>
      </AppBar>


      <Divider className={`${classes.mobileDivider} displaydivider`} style={{
        // color:'red',
        // backgroundColor:'red',
      }} />
      <Box justifyContent="center" className={`${classes.mobileMenu} displaymobilemenu mobileMenuStyleCss`}>
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.setMenuItems(props.constitutionMenu, 1);
            props.displayLeftSidebar(true);
            // console.log(props.constitutionMenu);
            history.push('/constitution/preamble');
          }}
          className={`${classes.mobileMenuItem} `}>
          Constitution
        </Box>

        <Box
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.setMenuItems(props.resourceMenu, 2);
            props.displayLeftSidebar(true);
            // console.log(props.resourceMenu);
            history.push('/resource');
          }}
          className={`${classes.mobileMenuItem} `}>
          Resources
        </Box>

        <Box style={{ cursor: 'pointer' }}

          onClick={() => {
            // props.setMenuItems([],4);
            // props.displayLeftSidebar(false);

            history.push('/law');
            // history.push('/laws');
          }}
          className={`${classes.mobileMenuItem} `}>
          Laws
        </Box>
      </Box>
      <RightSideBar />

      {/* <RightSideBar ref={btnRef}/> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    constitutionMenu: state.constitutionMenu.data,
    resourceMenu: state.resourceMenu.data,
    // currentLanguageType : state.currentLanguageType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openRightSidebar: (val) => dispatch(openRightSidebar(val)),
    setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
    displayLeftSidebar: (val) => dispatch(displayLeftSidebar(val)),
  }
}


// export default TopMenubar

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopMenubar)
