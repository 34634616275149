import React from 'react'
import { Typography } from '@material-ui/core';


const OldVersion = (props) => {

    const old_content = props.old_version.new_content
    // console.log('OldVersion_props', old_content)


    return (
        <div>
            {Object.keys(props.old_version).length !== 0 ?
                <Typography >
                    <div dangerouslySetInnerHTML={{ __html: old_content }} />
                </Typography>
                :
                <Typography >
                    <div >No old content present..</div>
                </Typography>
            }
        </div>
    )
}

export default OldVersion
