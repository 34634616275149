import React from 'react'
import TopMenubar from '../TopMenubarSection/TopMenubar'
import TopSearchBar from '../TopSearchBarSection/TopSearchBar'

function Header() {
    return (
        <>
        <TopMenubar/>
        <TopSearchBar/>
        </>
    )
}

export default Header
