import {HOST} from '../../../config/config'
import axios from 'axios'
import {getToken} from '../../../config/cookiesInfo'


export const fetchSections = (id, part, chapter) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}section/?law=${id}&part=${part}&chapter=${chapter}`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });

    })
}



export const fetchSpecificSection = (id) => {
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}section/${id}/`,
        {
            headers:{
                "Authorization" : `token ${getToken()}`
            }
        })
        .then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err)
        });
    })
}
