//perfect for english

import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';
// import axios from 'axios'


import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
// import LeftSidebar from '../../components/SidebarSection/LeftSidebar';

import useState from 'react-usestateref'

//fetchAmendmentListSuccess
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { fetchAmendmentList, fetchAmendmentListSuccess, setAmendmentLanguage, setAmendmentOffset, setAmendmentData, setAmendmentLoad } from '../../Redux/APIs/FetchAmendmentList/FetchAmendmentListActions'



const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto',

        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 17.5,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));


const Amendments = ({ language_type, amendmentList, amendmentListOffset, amendmentListLoad, amendmentListLanguage, ...props }) => {

    let { fetchAmendmentList, fetchAmendmentListSuccess, setAmendmentData, setAmendmentLanguage } = props;

    const classes = useStyles();


    const [data, setData, refData] = useState(amendmentList);
    const [offset, setOffset, refOffset] = useState(amendmentList.length === 0 ? 0 : amendmentList.length - 20);
    const [load, setLoad] = useState(amendmentListLoad);

    const history = useHistory();

    React.useEffect(() => {
        // window.scrollTo(0,0);
        props.setMenuItems([], 1);
        // firebaseAnalytics.logEvent(`AmendmentListPage_visited`);
        // console.log('inside setMenuItemsc')

    }, [])


    useEffect(() => {

        if (language_type !== amendmentListLanguage) {

            fetchAmendmentListSuccess([], 0, true);
            setData([]);
            setOffset(0);
            setLoad(true);
            setAmendmentLanguage(language_type);
        }

        //   fetchAmendmentListSuccess,setData,setOffset,setLoad,setAmendmentLanguage,amendmentListLanguage
    }, [language_type,])

    useEffect(() => {

        if (refData.current.length === 0) {

            fetchAmendmentList(refOffset.current, language_type, 2)
                .then((res) => {
                    if (load === true) {
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        if (res.data.next === null) {
                            setLoad(false);
                        }

                        setData([...refData.current, ...arr])
                        // setAmendmentData(refData.current);

                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        }

        // fetchAmendmentList,setData,load,refData,refOffset
    }, [language_type])


    const getNextData = (offset) => {

        setOffset(offset)
        fetchAmendmentList(refOffset.current, language_type, 2)
            .then((res) => {
                if (load === true) {
                    let arr = res.data.results;
                    arr.sort((a, b) => {
                        return a.id - b.id;
                    })
                    if (res.data.next === null) {
                        setLoad(false);
                    }
                    setData([...refData.current, ...arr])
                    // setAmendmentData(refData.current);
                }

            })
            .catch((err) => {
                console.log(err);
            });

    }

    return (

        <>
            <MainContentSection>

                <div className={classes.container}>
                    <h2 className={classes.heading}>Amendments</h2>
                    <hr className={classes.divider} />
                    <div className={classes.content}>

                        {
                            refData.current.length === 0 ?
                                <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                <InfiniteScroll
                                    dataLength={refData.current.length}
                                    next={() => {
                                        getNextData(refOffset.current + 20);
                                    }}
                                    hasMore={load}
                                    // loader={<h1>Loading...</h1>}
                                    loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                                    style={{ overflow: 'hidden' }}>

                                    <List>
                                        {
                                            refData.current.map((info, index) => {
                                                // console.log('Amendments_info',info)
                                                return (
                                                    <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                        setAmendmentData(refData.current);
                                                        // console.log('data legth',refData.current.length)
                                                        // console.log('data legth',amendmentList.length)
                                                        // history.push(`/constitution/amendments/${info.id}`,{content:info.content,title:info.title})}}>
                                                        history.push(`/constitution/amendments/${info.id}`, info)
                                                    }}>
                                                        <h5 className={classes.itemStyle}><b>{info.title}</b></h5>
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </InfiniteScroll>
                        }
                    </div>
                </div>
            </MainContentSection>
        </>
    )
}

// export default Amendments
const mapStateToProps = (state) => {

    let {
        toggleLanguage: { currentLanguageType },
        fetchAmendmentList: { data, offset, loadMore, language }

    } = state;


    return {
        language_type: currentLanguageType,
        amendmentList: data,
        amendmentListOffset: offset,
        amendmentListLoad: loadMore,
        amendmentListLanguage: language,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchAmendmentList: (offset, languageType, s) => dispatch(fetchAmendmentList(offset, languageType, s)),
        fetchAmendmentListSuccess: (list, offset, load) => dispatch(fetchAmendmentListSuccess(list, offset, load)),
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
        setAmendmentLanguage: (data) => dispatch(setAmendmentLanguage(data)),
        setAmendmentData: (data) => dispatch(setAmendmentData(data)),
        setAmendmentLoad: (data) => dispatch(setAmendmentLoad(data)),
        setAmendmentOffset: (data) => dispatch(setAmendmentOffset(data)),



    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Amendments)






