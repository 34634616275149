import {FETCH_SCHEDULE_SUCCESS,FETCH_SCHEDULE_FAILURE} from "./FetchScheduleListTypes"
// import store from '../../Store/store'
import {HOST} from '../../../config/config'
import axios from 'axios'

export const fetchScheduleListSuccess = (data) => {
    return {
        type : FETCH_SCHEDULE_SUCCESS,
        payload : data
    }
}
export const fetchScheduleListFailure = (error) => {
    return {
        type : FETCH_SCHEDULE_FAILURE,
        payload : error
    }
}

export const fetchScheduleList = (language_type) => {
    return  async (dispatch)=>{
        // console.log('dsdsdsdsd');
        try{
            // let language_type = store.getState().toggleLanguage.currentLanguageType;
            const res = await axios.get(`${HOST}schedules/?language_type=${language_type}`);
            // console.log(res);
    
            let array = res.data;

            array.sort((a,b)=>{
                return a.id - b.id
            })

            // console.log(arr);
            // setData(array)

            dispatch(fetchScheduleListSuccess(array));
            // console.log(arr);
            // return arr;
    
        }
        catch(e){
            console.log(e);
            dispatch(fetchScheduleListFailure(e));

        }
    
    }
}




export const fetchSpecificSchedule = (id) => {
    
    return new Promise((resolve, reject)=>{
        axios.get(`${HOST}schedules/${id}/`)
        .then((res)=>{
            resolve(res);
            
        }).catch((err)=>{
            reject(err)
        });

    })
   
}