import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import Label from '@material-ui/icons/Label';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'

import { connect } from 'react-redux'





const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    // '&MuiSvgIcon-root': {
    //   // backgroundColor: theme.palette.grey[200],
    //   display:'none'
    // },





    // color: 'theme.palette.text.secondary',
    '&:hover > $content': {
      // backgroundColor: theme.palette.grey[200],
      
    },
    '&:focus > $content, &$selected > $content': {
      // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
      // backgroundColor:theme.palette.grey[200],
      // color: 'green',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      // backgroundColor: '',
      // color:'#a01blacka58',//theme.palette.primary.dark   #03071e   #a01a58
    },
  },
  content: {
    color: theme.palette.text.secondary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 10,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: '#212529'
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  menuItemText:{
    // color:theme.palette.primary.main,
    // fontSize:15,
    marginLeft:-5,
    paddingLeft:10,

    [theme.breakpoints.up("xs")]: {
      fontSize:13,
      paddingLeft:10,
    },
    [theme.breakpoints.up("sm")]: {
      fontSize:14,
      paddingLeft:17,
    },
    [theme.breakpoints.up("md")]: {
      fontSize:14.7,
      paddingLeft:12,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize:14.9,
      paddingLeft:12,
    },
  },
  menuItemTextNested:{
    fontWeight:600,
    // marginTop:0,
  },
  grayColor:{
    color:'#343a40',
  },



  marginStyleOutside:{
    [theme.breakpoints.up("xs")]: {
      marginBottom:14,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom:14,
    }
  },
  marginStyleInside:{
    marginBottom:3,
    [theme.breakpoints.up("md")]: {
      marginBottom:4,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom:5,
    }
  },



}));






function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const history = useHistory();

  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor,nestedStyle,pageLink,pageData,start,outerStyle, ...other } = props;


      /*

let ostyle = outerStyle?
      {
        marginBottom:15,
      }:
      {
        marginBottom:3
      };

      */

  return (
    <TreeItem
    // style={{color:'black'}}
      label={
        <div className={classes.labelRoot} onClick={
          start===1?()=>{
            history.push(pageLink,pageData);
          }:()=>{}
        }>
          <Typography 
          variant="body2" 
          // onClick={
          //   start===1?()=>{
          //     history.push(pageLink,pageData);
          //   }:()=>{}
          // }
          className={nestedStyle?`${classes.menuItemText} ${classes.menuItemTextNested}`:`${classes.menuItemText} ${classes.grayColor}`}>
            {labelText}
          </Typography>
          
        </div>
      }
      // style={{
      //   // ...ostyle,
      //   // '--tree-view-color': 'green',
      //   // '--tree-view-bg-color': 'grey',
      // }}

      className={outerStyle?`${classes.marginStyleOutside}`:`${classes.marginStyleInside}`}

      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}

      collapseIcon = {<></>}
      expandIcon = {<></>}
    />
  );
}






StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};



 const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
    // color:'red'
  },
  spinner:{
    margin:'auto',
    display:'flex'
  }
});


const recursiveFunction = (obj,index,style,outerStyle)=>{ //style = outer item style(preamble,amendment,schedule,parts) and nested item

    if(obj.nestedItems.length<=0){
        return (<StyledTreeItem 
            nodeId={""+index} 
        labelText={obj.name} 
        labelIcon={Label}

        nestedStyle={style}
        pageLink={obj.pageLink}
        pageData={obj.data}
        start={1}
        outerStyle = {outerStyle}
        />)
    }
    return(
        <StyledTreeItem 
        nodeId={""+index}
        labelText={obj.name} 
        labelIcon={Label}

        nestedStyle={style}
        pageLink={obj.pageLink}
        pageData={obj.data}
        start={2}
        outerStyle = {outerStyle}
        >
            {
                obj.nestedItems.map((item,i)=>{
                  const s = item.nestedItems.length===0?false:true;
                    return (<React.Fragment key={i}>{recursiveFunction(item,Number(""+index+""+i),s,false)}</React.Fragment>);
                })
            }
        </StyledTreeItem>
    )
}






function LeftSidebarTree(props) {

  const classes = useStyles();

  return (
    <TreeView
      // className={classes.root}
      defaultExpanded={['3']}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}>
          {
            props.menuIndex===1?
              (
                props.constitutionMenu.length!==0?
                (props.constitutionMenu.map((item,index)=>{
                return (<React.Fragment key={index}>{recursiveFunction(item,index,true,true)}</React.Fragment>);
                })):
                <CircularProgress className={classes.spinner} />
              ):
              props.menuIndex===2?
                (props.resourceMenu.map((item,index)=>{
                  return (<React.Fragment key={index}>{recursiveFunction(item,index,true,true)}</React.Fragment>);
                })) : <></>

          }
    </TreeView>
  );
}












const mapStateToProps = (state) =>{
  return {
    menuItems : state.setMenuItem.data,
     menuIndex : state.setMenuItem.index,
     constitutionMenu : state.constitutionMenu.data,
     resourceMenu : state.resourceMenu.data,
  }
}


export default connect(
  mapStateToProps,
  null
)(LeftSidebarTree)






























