import React from 'react'
import { Typography } from '@material-ui/core';
// const Diff = require('diff');

const CurrentVersion = (props) => {
    const new_content = props.current_version
    // console.log('CurrentVersion_props', new_content)

    const old_content = props.old_version

    return (
        <div>
            <Typography >
                <div dangerouslySetInnerHTML={{ __html: new_content }} />
            </Typography>
        </div>
    )
}

export default CurrentVersion
