import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux'
import { displayLeftSidebar } from '../../Redux/DisplayLeftSidebar/DisplayLeftSidebarActions'
import { firebaseAnalytics } from '../../config/firebase-config'
import { Redirect } from 'react-router-dom'
import { getStaffStatus } from '../../config/cookiesInfo';
const useStyles = makeStyles((theme) => ({
    containerStyle: {
        position: 'relative',
        width: '100%'
    }
}));


function Homepage(props) {
    const classes = useStyles();
    // console.log('Homepage_props',props)
    React.useEffect(() => {
        props.displayLeftSidebarFn(false);
        //firebaseAnalytics.logEvent(`HomePage_visited`);
        return () => {
            props.displayLeftSidebarFn(true);
        }
    }, [])


    return (
        <>
            {getStaffStatus() ?

                <Redirect to='/admin/dashboard' />
                :
                <Redirect to='/constitution/preamble' />
            }
        </>


        //     <React.Fragment>
        //     <CssBaseline />
        //         <Container className={`${classes.containerStyle}`}>
        //             <Box  color="text.primary" style={{backgroundColor:'#ffffff',minHeight:'100vh',paddingBottom:'50px'}}>
        //                <h3 style={{paddingTop:300}}>
        //                Landing Page/ Home page
        //                </h3>
        //             </Box>
        //         </Container>
        //   </React.Fragment>





    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        displayLeftSidebarFn: (value) => dispatch(displayLeftSidebar(value)),
    }
}


export default connect(
    null,
    mapDispatchToProps
)(Homepage)