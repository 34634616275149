
//final ConstituentAssemblyDebates
import React, { useEffect } from 'react'
import MainContentSection from '../../components/MainContent/MainContentSection'
import { makeStyles } from '@material-ui/core/styles';

import InfiniteScroll from 'react-infinite-scroll-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'


import useState from 'react-usestateref'

import { fetchDebateList, fetchDebateListSuccess, setDebateLanguage, setDebateData, setDebateLoad, setDebateOffset, setDebateYear } from '../../Redux/APIs/FetchDebatesList/FetchDebateListActions'
//fetchAmendmentListSuccess
import { setMenuItems } from '../../Redux/SetCurrentMenuItems/SetCurrentMenuItemsActions'
import { logoutUser } from '../../config/firebaseAuthentication';

// import { firebaseAnalytics } from '../../config/firebase-config'


const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
        paddingTop: 30,
        fontWeight: 600,
        // [theme.breakpoints.down('xs')]:{
        //     paddingTop:20,
        //     fontSize:25
        // }
        [theme.breakpoints.up('xs')]: {
            paddingTop: 15,
            fontSize: 22,
            marginBottom: -7,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 25,
            fontSize: 24,
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 31,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 35,
            fontSize: 26,
        }
    },
    divider: {
        width: '90%',
        align: 'center',
        backgroundColor: 'black'
    },
    content: {

        // backgroundColor:'red',
        width: '80%',
        margin: 'auto',
        [theme.breakpoints.up('xs')]: {
            marginTop: -10,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: -7,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        }

    },
    container: {
        align: 'center',

    },
    spinner: {
        margin: "auto",
        marginTop: "5%",
        // padding:'auto',
        color: 'blue',
        // display:'flex',
        // justifyContent: 'center'
    },
    spinnerContainer: {
        display: "flex"
    },
    itemStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 15,
            marginBottom: 3,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
            marginBottom: 5,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
            marginBottom: 5,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 18,
            marginBottom: 6,
        },
    }

}));


const ConstituentAssemblyDebates = ({ language_type, debateList, debateListOffset, debateListLoad, debateListLanguage, debateListYear, ...props }) => {

    const history = useHistory();

    // console.log('state = ',props.location.state)
    let { fetchDebateList, fetchDebateListSuccess, setDebateData, setDebateYear, setDebateLoad } = props;

    const classes = useStyles();

    // console.log('first console -',props.location.state.year)


    const [data, setData, refData] = useState(debateList);
    const [offset, setOffset, refOffset] = useState(debateList.length === 0 ? 0 : debateList.length - 20);//
    const [load, setLoad, refLoad] = useState(debateListLoad);
    const [year, setYear, refYear] = useState();

    // console.log(refYear.current)


    let yyear = props.location.state ? props.location.state.year : 1946;


    React.useEffect(() => {
        props.setMenuItems([], 2);
        // console.log(props.location.state.year)
        //firebaseAnalytics.logEvent(`DecateListPage_visited`);

    }, [])

    // React.useEffect(()=>{
    //     // props.setMenuItems([],2);
    //     setYear((prev)=>yyear);

    // },[yyear])


    useEffect(() => {

        // console.log('inside useeffect 2nd');
        if (yyear !== debateListYear) {

            fetchDebateListSuccess([], 0, true);
            setData([]);
            setOffset(0);
            setLoad(true);
            setDebateYear(yyear);


            // console.log('------------------------------------------');
            // console.log('setData',data);
            // console.log('setoffset',offset);
            // console.log('setData',data);
        }

        //   fetchAmendmentListSuccess,setData,setOffset,setLoad,setAmendmentLanguage,amendmentListLanguage
    }, [yyear])



    useEffect(() => {

        // console.log('inside useeffect data fetch');
        if (refData.current.length === 0) {
            // console.log('inside useeffect data fetch inside if');
            fetchDebateList(refOffset.current, yyear, history)
                .then((res) => {
                    console.log('RESULT', res);

                    if (refLoad.current === true) {
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        if (res.data.next === null) {
                            setLoad(false);
                            setDebateLoad(false)
                        }

                        setData([...refData.current, ...arr])
                        setDebateData(refData.current);

                    }
                })
                .catch((err) => {
                    if (err.response) {
                        if (err.response.status === 401) {
                            logoutUser(history)
                            history.push("/")
                        }
                    }
                });

        }
        // fetchAmendmentList,setData,load,refData,refOffset
    }, [yyear])



    const getNextData = (offset) => {

        setOffset(offset)
        fetchDebateList(refOffset.current, yyear)
            .then((res) => {
                if (res.data.results) {
                    if (load === true) {
                        let arr = res.data.results;
                        arr.sort((a, b) => {
                            return a.id - b.id;
                        })
                        if (res.data.next === null) {
                            setLoad(false); setDebateLoad(false)
                        }
                        setData([...refData.current, ...arr])
                        setDebateData(refData.current);
                    }
                }
            })
            .catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        logoutUser(history)
                        history.push("/")
                    }
                }
            });
    }





    return (

        <>
            {/* {console.log('state = ',props.location.state)} */}
            {

                // props.location.state=='undefined'?//|| !props.location.state.content?
                // <Redirect to={{
                //     pathname:'/',
                // }} />:

                <MainContentSection>
                    <div className={classes.container}>
                        <h2 className={classes.heading}>Debates</h2>
                        <hr className={classes.divider} />
                        <div className={classes.content}>

                            {
                                refData.current.length === 0 ?
                                    <div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div> :
                                    <InfiniteScroll
                                        dataLength={refData.current.length}
                                        next={() => {
                                            getNextData(refOffset.current + 20);

                                        }}
                                        hasMore={load}
                                        loader={<div className={classes.spinnerContainer}><CircularProgress className={classes.spinner} /></div>}
                                        style={{ overflow: 'hidden' }}>

                                        <List>
                                            {
                                                refData.current.map((info, index) => {
                                                    return (
                                                        <ListItem style={{ cursor: 'pointer' }} key={index} onClick={() => {
                                                            setDebateData(refData.current);
                                                            history.push(`/resource/constituentAssemblyDebates/${info.id}`)
                                                        }}>
                                                            <h5 className={classes.itemStyle}><b>{info.title}</b></h5>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>
                                    </InfiniteScroll>
                            }

                        </div>
                    </div>
                </MainContentSection>
            }
        </>
    )
}

// export default Amendments
const mapStateToProps = (state) => {

    let {
        fetchDebateList: { data, offset, loadMore, language, year }
    } = state;


    return {
        // language_type : currentLanguageType,
        debateList: data,
        debateListOffset: offset,
        debateListLoad: loadMore,
        debateListLanguage: language,
        debateListYear: year,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchDebateList: (offset, year) => dispatch(fetchDebateList(offset, year)),
        fetchDebateListSuccess: (list, offset, load) => dispatch(fetchDebateListSuccess(list, offset, load)),
        setMenuItems: (val, i) => dispatch(setMenuItems(val, i)),
        setDebateLanguage: (data) => dispatch(setDebateLanguage(data)),
        setDebateData: (data) => dispatch(setDebateData(data)),
        setDebateLoad: (data) => dispatch(setDebateLoad(data)),
        setDebateOffset: (data) => dispatch(setDebateOffset(data)),
        setDebateYear: (data) => dispatch(setDebateYear(data)),

    }
}





export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConstituentAssemblyDebates)










